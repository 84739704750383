import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as API from '../../API';
import { paramsToLower } from '../../classes/Donation';
import { Fund } from '../../classes/Fund';
import { Metrics } from '../../classes/Metrics';
import { inputSuccess } from '../../Helpers';

export default function SelectFund(
  { error, setError, setFund, metrics, setAppealId }:
  {
    error: boolean,
    setError: React.Dispatch<React.SetStateAction<boolean>>,
    setFund: React.Dispatch<React.SetStateAction<Fund>>,
    metrics: Metrics,
    setAppealId: React.Dispatch<React.SetStateAction<string>>
}) {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchFocused, setSearchFocused] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<Array<Fund>>([]);
  const [selectedFund, setSelectedFund] = useState<Fund>(new Fund());
  const [otherFund, setOtherFund] = useState<string>('');
  const [otherFundError, setOtherFundError] = useState<string | undefined>(undefined);
  const [searching, setSearching] = useState<boolean>(false);
  const [placeholder, setPlaceholder] = useState<string>('Search for a fund');
  const linkData = {
    event: 'Custom click',
    click_category: 'UQ giving donation app',
    click_action: 'Select a fund',
  };

  const history = useHistory();

  const defaultFunds = useMemo(() => {
    return [
      {
        id: 'DEFAULT_DO_NOT_USE',
        shortName: 'Discover funds on UQ Giving',
        longName: '',
        friendlyName: 'Discover funds on UQ Giving',
        overview: '',
        position: 0,
        chartString: '',
      },
      {
        id: Fund.OTHER,
        shortName: 'Specify where you want to donate',
        longName: 'I want to specify where my donation goes',
        friendlyName: 'I want to specify where my donation goes',
        overview: '',
        position: 0,
        chartString: '',
      },
    ];
  }, []);

  const search = useCallback(async (searchTerm: string) => {
    try {
      const response = await API.search(searchTerm);
      const results = response.hits.map((item: any) => {
        return item._source;
      });
      results.push(...defaultFunds);
      setSearchResults(results);
      setSearching(false);
    } catch (error) {
      console.error(error);
    }
  }, [defaultFunds]);

  useEffect(() => {
    const lowerCaseParams = paramsToLower(history);
    if (lowerCaseParams.has('appeal_id')) {
      setAppealId(lowerCaseParams.get('appeal_id') || '');
    }

    // Debounce to not overload calls on every keystroke
    const debounce = setTimeout(() => {
      if (searchTerm.length >= 3) {
        setSearching(true);
        search(searchTerm);
      } else {
        setSearchResults([]);
        setSelectedFund(new Fund());
        setSearching(false);
      }
    }, 300);

    return () => clearTimeout(debounce)
  }, [searchTerm, search, setAppealId]);

  return (
    <div className="uq-section uq-section--centered">
      <div className="uq-section__container">
        <div className="uq-section__content">
          <h1 className="homepage-heading">Thank you for your support!</h1>
          <p className="homepage-destination">Please choose an area where you would like your donation to go</p>
          <div className="fund-search">
            {error &&
              <div className="uq-alert uq-alert--warning" role="alert">
                <div className="uq-alert__message">
                  <p>Fund not found, select a new fund below.</p>
                </div>
              </div>
            }
            <label htmlFor="fund-search" className="visually-hidden">Search</label>
            <input
              placeholder={placeholder}
              autoComplete="off"
              className={searching ? "uq-input--large search--loading" : "uq-input--large"}
              type="text"
              id="fund-search"
              name="search"
              value={searchTerm}
              onChange={event => setSearchTerm(event.target.value)}
              onFocus={() => {
                setSearchFocused(true);
                setPlaceholder('Try searching for terms like "health" or "greatest need"');
              }}
              onBlur={() => {
                setTimeout(() => {
                  setSearchFocused(false);
                  setPlaceholder('Search for a fund');
                }, 10);
              }}
            />
            {searching &&
              <div
                className={`uq-loading-spinner fund-search__spinner fund-search__spinner--${error ? 'error' : 'normal'}`}
                role="alert"
                aria-live="assertive"
              >
                <span>Searching...</span>
              </div>
            }

            {(searchResults && searchResults.length) && searchFocused ?
              <ul className="fund-search-list fund-list">
                {searchResults.map((fund) => {
                  return <li className="fund-search-list__item" key={fund.id}
                    onMouseDownCapture={() => {
                      if (fund.id === 'DEFAULT_DO_NOT_USE') {
                        window.location.href = 'https://alumni.uq.edu.au/giving/';
                      } else {
                        setSelectedFund(fund);
                        metrics.pushData({
                          ...linkData,
                          click_label: fund.friendlyName,
                          click_url: undefined
                        });
                      }
                    }}
                  >{fund.friendlyName}</li>
                })}
              </ul> : ''
            }

            {selectedFund && selectedFund.friendlyName.length ?
              <div className="uq-card fund-card">
                <div className="uq-card__content-wrapper">
                  <div className="uq-card__content">
                    <div className="uq-card__header">
                      <h3 className="uq-card__title">{selectedFund.friendlyName}</h3>
                    </div>
                    <div className="uq-card__body">
                      <p>{selectedFund.overview}</p>
                    </div>
                  </div>
                  <div className="uq-card__actions">
                    {selectedFund.id === Fund.OTHER ?
                      <>
                        <label htmlFor="otherFund">Please enter a cause you would like to donate to:</label>
                        <input
                          className="uq-input--large"
                          type="text"
                          id="otherFund"
                          name="otherFund"
                          value={otherFund}
                          onChange={event => setOtherFund(event.target.value)}
                          onBlur={(event) => {
                            inputSuccess(event);
                          }}
                          aria-describedby="otherFundFeedback"
                        />
                        {otherFundError ?
                          <span id="otherFundFeedback"
                                className="uq-error-message"
                                aria-live="polite"
                          >
                              {otherFundError}
                          </span>
                        : '' }
                      </>
                    : '' }
                    <button
                      className="uq-button"
                      onClick={(e) => {
                        if (selectedFund.id === Fund.OTHER) {
                          selectedFund.friendlyName = otherFund;
                        }
                        if (selectedFund.id === Fund.OTHER && (!otherFund || otherFund.length <= 3)) {
                          setOtherFundError('Please provide a description of where your donation should be directed.');
                          e.preventDefault();
                        } else {
                          setOtherFundError('');
                          setError(false);
                          setFund(selectedFund);
                          metrics.pushData({
                            ...linkData,
                            click_label: selectedFund.friendlyName,
                            click_url: `${window.location.href}${selectedFund.url || selectedFund.id}`
                          });
                          history.push(`/${selectedFund.url || selectedFund.id}`);
                        }
                      }}
                    >Donate</button>
                  </div>
                </div>
              </div>
            : ''}
          </div>
        </div>
      </div>
    </div>
  );
}
