import { History } from 'history';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { listAdmins, removeAdmin, upsertAdmin } from '../../API';
import { AlertLevel } from '../Alert/Alert';

interface User {
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  lastLogin?: string;
}

export default function Administrators(
  { setAlertLevel, setAlertMessage, setAlertDisplay, setAlertSpinner, adminUrl, history }:
    {
      setAlertLevel: React.Dispatch<React.SetStateAction<AlertLevel>>,
      setAlertMessage: React.Dispatch<React.SetStateAction<string>>,
      setAlertDisplay: React.Dispatch<React.SetStateAction<boolean>>,
      setAlertSpinner: React.Dispatch<React.SetStateAction<boolean>>,
      adminUrl: string,
      history: History,
    }
): JSX.Element {
  const [list, setList] = useState<Array<User>>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [newUser, setNewUser] = useState<boolean>(false);
  const [user, setUser] = useState<User|undefined>(undefined);
  const [password, setPassword] = useState<string|undefined>(undefined);
  const [confirmPassword, setConfirmPassword] = useState<string|undefined>(undefined);
  // Validations
  const [usernameMessage, setUsernameMessage] = useState<string>('');
  const [firstNameMessage, setFirstNameMessage] = useState<string>('');
  const [lastNameMessage, setLastNameMessage] = useState<string>('');
  const [emailMessage, setEmailMessage] = useState<string>('');
  const [passwordMessage, setPasswordMessage] = useState<string>('');
  const [confirmPasswordMessage, setConfirmPasswordMessage] = useState<string>('');

  const timer = useRef<ReturnType<typeof setTimeout>>();

  /**
   * load the initial data via callback to prevent loops on data change
   */
  const loadInitialData = useCallback( async (updateSpinner = true) => {
    if (updateSpinner) {
      setAlertLevel(AlertLevel.INFO);
      setAlertMessage('Loading...');
      setAlertSpinner(true);
      setAlertDisplay(true);
    }
    const listResponse = await listAdmins();
    if (listResponse.ok) {
      const admins = await listResponse.json();
      setList(admins);
      if (updateSpinner) {
        setAlertDisplay(false);
      }
    } else {
      setAlertLevel(AlertLevel.ERROR);
      setAlertMessage('An error has occurred. Please try again later.');
      setAlertSpinner(false);
      setAlertDisplay(true);
    }
  }, [setAlertLevel, setAlertMessage, setAlertDisplay, setAlertSpinner]);

  useEffect(() => {
    loadInitialData();
    return () => {
      if (timer.current !== undefined) {
        clearTimeout(timer.current);
      }
    };
  }, [loadInitialData]);

  /**
   * Delete an admin
   *
   * @param username
   *
   * @return Promise<void>
   */
  async function handleDelete(username: string|undefined): Promise<void> {
    try {
      if (! username) {
        setAlertSpinner(false);
        setAlertLevel(AlertLevel.ERROR);
        setAlertMessage('Error, username not found');
        setAlertDisplay(true);
        timer.current = setTimeout(() => {
          setAlertDisplay(false);
        }, 5000);
        return;
      }

      setAlertSpinner(true);
      setAlertLevel(AlertLevel.INFO);
      setAlertMessage('Removing admin');
      setAlertDisplay(true);
      setShowModal(false);

      const response = await removeAdmin(username);

      if (! response.ok) {
        throw new Error(response.statusText);
      }

      setAlertSpinner(false);
      setAlertLevel(AlertLevel.INFO);
      setAlertMessage('Admin user has been deleted');
      setAlertDisplay(true);
      timer.current = setTimeout(() => {
        setAlertDisplay(false);
      }, 5000);
      await loadInitialData(false);
    } catch (error) {
      console.error(error);
      setAlertSpinner(false);
      setAlertLevel(AlertLevel.ERROR);
      setAlertMessage('An error has occurred');
      setAlertDisplay(true);
    }
  }

  /**
   * Close the modal
   */
  function handleClose() {
    setShowModal(false);
    setUser(undefined);
  }

  /**
   * Given a username, check basic validation
   *
   * @param username
   *
   * @return boolean
   */
  function validateUsername(username: string|undefined): boolean {
    let valid = false;
    if (! username) {
      setUsernameMessage('Please enter a valid UQ username');
    } else {
      setUsernameMessage('');
      valid = true;
    }
    return valid;
  }

  /**
   * Given a firstName, check basic validation
   *
   * @param firstName
   *
   * @return boolean
   */
  function validateFirstName(firstName: string|undefined): boolean {
    let valid = false;
    if (! firstName || firstName.length < 2) {
      setFirstNameMessage('Please enter a first name of 2 or more characters');
    } else {
      setFirstNameMessage('');
      valid = true;
    }
    return valid;
  }

  /**
   * Given a lastName, check basic validation
   *
   * @param lastName
   *
   * @return boolean
   */
  function validateLastName(lastName: string|undefined): boolean {
    let valid = false;
    if (! lastName || lastName.length < 1) {
      setLastNameMessage('Please enter a last name');
    } else {
      setLastNameMessage('');
      valid = true;
    }
    return valid;
  }

  /**
   * Given an email Address, check basic validation
   *
   * @param email
   *
   * @return boolean
   */
  function validateEmail(email: string|undefined): boolean {
    let valid = false;
    if (! email || email.length <= 10) {
      setEmailMessage('Please enter an email address');
    } else {
      setEmailMessage('');
      valid = true;
    }
    return valid;
  }

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    let valid = true;

    if (!user) {
      setAlertSpinner(false);
      setAlertLevel(AlertLevel.ERROR);
      setAlertMessage('Please complete the form before continuing');
      setAlertDisplay(true);
      timer.current = setTimeout(() => {
        setAlertDisplay(false);
      }, 5000);
      return;
    }

    if (! validateUsername(user.username)) {
      valid = false;
    }

    if (! validateFirstName(user.firstName)) {
      valid = false;
    }

    if (! validateLastName(user.lastName)) {
      valid = false;
    }

    if (! validateEmail(user.email)) {
      valid = false;
    }

    // New users require a password to be set
    if (newUser && (! password || (password && password.length <= 6))) {
      valid = false;
      setPasswordMessage('Please set a secure password');
    } else {
      setPasswordMessage('');
    }

    // Existing users only need a password if it is getting updated
    if (! newUser && password) {
      if (password.length <= 6) {
        valid = false;
        setPasswordMessage('Please use a secure password');
      }
    }

    if (password !== confirmPassword) {
      valid = false;
      setConfirmPasswordMessage('Passwords do not match');
    } else {
      setConfirmPasswordMessage('');
    }

    if (! valid) {
      // Do not proceed
      return;
    }

    try {
      setAlertSpinner(true);
      setAlertLevel(AlertLevel.INFO);
      setAlertMessage('Saving...');
      setAlertDisplay(true);

      const response = await upsertAdmin({...user, password});
      if (! response.ok) {
        throw new Error(response.statusText);
      }
      setShowForm(false);
      setAlertSpinner(false);
      setAlertLevel(AlertLevel.SUCCESS);
      setAlertMessage('Admin saved successfully');
      setAlertDisplay(true);
      await loadInitialData(false);
      timer.current = setTimeout(() => {
        setAlertDisplay(false);
      }, 5000);
    } catch (error) {
      console.error(error);
    }
  }, [loadInitialData, user, newUser, password, confirmPassword, setAlertSpinner, setAlertDisplay, setAlertMessage, setAlertLevel]);

  return (
    <>
      <div className="uq-layout-container admin-container">
        <h1>eDonations Administration - Admin Users</h1>
        <div className="admin-container col-button">
          <button
            className="uq-button uq-icon uq-icon--standard--add uq-icon--light"
            onClick={() => {
              setUser(undefined);
              setNewUser(true);
              setPassword(undefined);
              setConfirmPassword(undefined);
              setShowForm(true);
            }}
          >
            Add New Admin
          </button>
        </div>

        {/* DELETE MODAL */}
        <div aria-modal={true} className={`admin-modal ${showModal ? '' : 'admin-modal--hide' }`}>
          <div className="admin-modal__overlay" onClick={handleClose} />
          <div className="admin-modal__content">
            <div className="admin-modal__header">
              <b>Delete admin</b>
              <button className="uq-button uq-button--secondary uq-button--outline" onClick={handleClose}>Close</button>
            </div>
            <div>
              <p>Are you sure you want to delete the user <b>{user?.username}</b>?</p>
              <div className="admin-modal__buttons">
                <button className="uq-button uq-button--alert" onClick={() => handleDelete(user?.username)} >Remove User</button>
                <button className="uq-button uq-button--secondary" onClick={handleClose}>Cancel</button>
              </div>
            </div>
          </div>
        </div>

        {/* UPSERT ADMIN FORM */}
        <form
          className="margin-b1"
          onSubmit={(event) => handleSubmit(event)}
          style={{display: showForm ? 'block' : 'none'}}
        >
          <h2 style={{marginTop: '0.5rem'}}>{newUser ? 'Add' : 'Update'} Admin</h2>
          <div className="uq-grid uq-grid--halves">
            <div className="uq-grid__col">
              <label htmlFor="username">Username<sup>*</sup>:</label>
              <input
                type="text"
                name="username"
                placeholder="UQ Username"
                value={user?.username || ''}
                aria-describedby="usernameFeedback"
                onBlur={(event) => validateUsername(event.target.value)}
                onChange={(event) => setUser({...user, username: event.target.value})}
              />
              {usernameMessage && usernameMessage.length ?
                <span className="uq-error-message" id="usernameFeedback" aria-live="polite">
                {usernameMessage}
              </span> :
                ''
              }
            </div>
            <div className="uq-grid__col">
              <label htmlFor="email">Email<sup>*</sup>:</label>
              <input
                type="text"
                name="email"
                placeholder="Email"
                value={user?.email || ''}
                aria-describedby="emailFeedback"
                onBlur={(event) => validateEmail(event.target.value)}
                onChange={(event) => setUser({...user, email: event.target.value})}
              />
              {emailMessage && emailMessage.length ?
                <span className="uq-error-message" id="emailFeedback" aria-live="polite">
                {emailMessage}
              </span> :
                ''
              }
            </div>
            <div className="uq-grid__col">
              <label htmlFor="firstName">First Name<sup>*</sup>:</label>
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={user?.firstName || ''}
                aria-describedby="firstNameFeedback"
                onBlur={(event) => validateFirstName(event.target.value)}
                onChange={(event) => setUser({...user, firstName: event.target.value})}
              />
              {firstNameMessage && firstNameMessage.length ?
                <span className="uq-error-message" id="firstNameFeedback" aria-live="polite">
                {firstNameMessage}
              </span> :
                ''
              }
            </div>
            <div className="uq-grid__col">
              <label htmlFor="lastName">Last Name<sup>*</sup>:</label>
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={user?.lastName || ''}
                aria-describedby="lastNameFeedback"
                onBlur={(event) => validateLastName(event.target.value)}
                onChange={(event) => setUser({...user, lastName: event.target.value})}
              />
              {lastNameMessage && lastNameMessage.length ?
                <span className="uq-error-message" id="lastNameFeedback" aria-live="polite">
                {lastNameMessage}
              </span> :
                ''
              }
            </div>
            <div className="uq-grid__col">
              <label htmlFor="phone">Phone:</label>
              <input
                type="text"
                name="phone"
                placeholder="Phone"
                value={user?.phone || ''}
                onChange={(event) => setUser({...user, phone: event.target.value})}
              />
            </div>
          </div>
          <div className="uq-grid uq-grid--halves">
            <div className="uq-grid__col">
              <label htmlFor="password">{user ? 'Update' : 'Set'} Password{user ? '' : <sup>*</sup>}:</label>
              <input
                type="password"
                name="password"
                placeholder="New Password"
                value={password}
                aria-describedby="passwordFeedback"
                onChange={(event) => setPassword(event.target.value)}
              />
              {passwordMessage && passwordMessage.length ?
                <span className="uq-error-message" id="passwordFeedback" aria-live="polite">
                {passwordMessage}
              </span> :
                ''
              }
            </div>
            <div className="uq-grid__col">
              <label htmlFor="password">Confirm New Password{user ? '' : <sup>*</sup>}:</label>
              <input
                type="password"
                name="password_confirmation"
                placeholder="Confirm Password"
                value={confirmPassword}
                aria-describedby="confirmPasswordFeedback"
                onChange={(event) => setConfirmPassword(event.target.value)}
              />
              {confirmPasswordMessage && confirmPasswordMessage.length ?
                <span className="uq-error-message" id="confirmPasswordFeedback" aria-live="polite">
                {confirmPasswordMessage}
              </span> :
                ''
              }
            </div>
          </div>
          <div>
            <div className="col-button">
              <button className="uq-button uq-button--secondary" type="button" onClick={() => setShowForm(false)}>Cancel</button>
              &nbsp;
              <button className="uq-button" type="submit">Save Admin</button>
            </div>
          </div>
        </form>

        {/* ADMIN LIST */}
        <div className="uq-table__wrapper">
          <table className="uq-table admin-table">
            <thead>
            <tr>
              <th>Username</th>
              <th>Name</th>
              <th>Email</th>
              <th>Update/remove</th>
            </tr>
            </thead>
            <tbody>
              { Object.entries(list).map(([key, user]) => {
                return <tr key={`${key}`}>
                  <td>{ user.username }</td>
                  <td>{ user.firstName } { user.lastName }</td>
                  <td>{ user.email }</td>
                  <td style={{ display:'flex', justifyContent: 'space-evenly' }}>
                    <button
                      className="uq-button uq-icon uq-icon--other--content-notebook-pencil uq-icon--light"
                      onClick={() => {
                        setUser(user);
                        setNewUser(false);
                        setShowForm(true);
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      Edit Admin
                    </button>

                    <button
                      className="uq-button uq-button--alert uq-icon uq-icon--standard--error-circle uq-icon--light"
                      onClick={() => {
                        setUser(user);
                        setShowForm(false);
                        setShowModal(true);
                      }}
                    >
                      Delete Admin
                    </button>
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
