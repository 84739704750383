import React from 'react';
import { Metrics } from '../../classes/Metrics';

export default function SiteHeader({metrics}: {metrics: Metrics}) {
  const linkData = {
    event: 'Custom click',
    click_category: 'Header',
    click_action: 'Link click',
  };

  function handleLink(event: any) {
    return metrics.pushData({
      ...linkData,
      click_label: event.target.innerText,
      click_url: event.target.href,
    });
  }

  return (
    <div className="uq-site-header">
      {/* Site title and utility area with mobile nav toggler (JS) */}
      <div className="uq-site-header__title-container">
        <div className="uq-site-header__title-container__left">
          <a href="/" className="uq-site-header__title">Donations</a>
        </div>
        <div className="uq-site-header__title-container__right">
          <button className="uq-site-header__navigation-toggle jsNavToggle" type="button">Menu</button>
        </div>
      </div>

      {/* Navigation Menu */}
      <div className="uq-site-header__navigation-container">
        <nav className="uq-site-header__navigation" aria-label="Site navigation" id="jsNav">
          <ul className="uq-site-header__navigation__list
                 uq-site-header__navigation__list--level-1">
            <li className="uq-site-header__navigation__list-item">
              <a href="https://alumni.uq.edu.au/give-uq/staff-giving-0" onClick={event => handleLink(event)}>Staff giving</a>
            </li>
            <li className="uq-site-header__navigation__list-item">
              <a href="https://alumni.uq.edu.au/give-uq/wills-and-bequests" onClick={event => handleLink(event)}>Gifts in Wills</a>
            </li>
            <li className="uq-site-header__navigation__list-item">
              <a href="https://alumni.uq.edu.au/contact-us" onClick={event => handleLink(event)}>Contact us</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
