import dayjs from 'dayjs';
import { History } from 'history';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getConfig, getDonations, saveProcessState } from '../../API';
import { Donation } from '../../classes/Donation';
import { countries, createMultiArrayCSV } from '../../Helpers';
import Alert, { AlertLevel } from '../Alert/Alert';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

export default function Donations(
  { setAlertLevel, setAlertMessage, setAlertDisplay, setAlertSpinner, adminUrl, history }:
  {
    setAlertLevel: React.Dispatch<React.SetStateAction<AlertLevel>>,
    setAlertMessage: React.Dispatch<React.SetStateAction<string>>,
    setAlertDisplay: React.Dispatch<React.SetStateAction<boolean>>,
    setAlertSpinner: React.Dispatch<React.SetStateAction<boolean>>,
    adminUrl: string,
    history: History,
  }
): JSX.Element {
  // Prepare default date filters - Current month
  const firstDay = dayjs().startOf('month').format('YYYY-MM-DD');
  const lastDay = dayjs().endOf('month').format('YYYY-MM-DD');

  const errorTimeout = 5000;

  const [timeFrom, setTimeFrom] = useState<string>(firstDay);
  const [timeTo, setTimeTo] = useState<string>(lastDay);
  const [status, setStatus] = useState<string>('all');
  const [processed, setProcessed] = useState<string>('all');
  const [sortedResults, setSortedResults] = useState<Array<Donation>>([]);
  const [error, setError] = useState<string>('');
  const [errorLevel, setErrorLevel] = useState<AlertLevel>(AlertLevel.ERROR);
  const [download, setDownload] = useState<string>('');
  const [updating, setUpdating] = useState<object>({});
  const [checkedStates, setCheckedStates] = useState<object>({});
  const [spinner, setSpinner] = useState<boolean>(true);
  const [spinnerText, setSpinnerText] = useState<string>('Loading results');
  const [errorDisplay, setErrorDisplay] = useState<boolean>(false);

  const timer = useRef<ReturnType<typeof setTimeout>>();

  /**
   * Fetch donations based on the currently selected options
   */
  async function fetchDonations() {
    const input = {
      startTime: dayjs(timeFrom).toISOString(),
      endTime: dayjs(`${timeTo}`).toISOString(),
    };

    try {
      const apiResponse = await getDonations(input);
      if (apiResponse.ok) {
        const checkedItems: { [key: string]: boolean; } = {};
        const items = await apiResponse.json();

        items.forEach((item: Donation) => {
          checkedItems[String(item.identifier)] = item.processed;
        })
        // Set local states for usage with loaded data and editable data
        setCheckedStates((checkedStates) => {
          return {
            ...checkedStates,
            ...checkedItems
          };
        });

        // Default sort until we build out ordering functionality
        const sorted = items.sort((a: Donation, b: Donation) => (a.createdAt > b.createdAt) ? -1 : ((b.createdAt > a.createdAt) ? 1 : 0));
        setSortedResults(sorted);
        setSpinner(false);
      } else if (apiResponse.status === 403) {
        history.push(adminUrl, { message: 'Session expired. Please sign in to continue', level: AlertLevel.WARNING });
      }
    } catch (error) {
      console.error(error);
      setSpinner(false);
      setAlertLevel(AlertLevel.ERROR);
      setAlertMessage('Unable to connect to API. Please refresh your page or try again later. If the problem persists please contact its.softwareservices@uq.edu.au');
      setAlertDisplay(true);
      setAlertSpinner(false);
    }
  };

  useEffect(() => {
    fetchDonations();
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  /**
   * Keep track of what we need to submit to update, and the states to display
   *
   * @param id
   * @param checked
   *
   * @return void
   */
  function setProcess(id: string, checked: boolean) {
    setUpdating({...updating, [id]: checked});
    setCheckedStates({
      ...checkedStates,
      [id]: checked
    });
  }

  const saveProcess = useCallback(async () => {
    if (Object.keys(updating).length === 0) {
      setErrorLevel(AlertLevel.WARNING);
      setError('No changes to save');
      setErrorDisplay(true);
      timer.current = setTimeout(() => {
        setErrorDisplay(false);
      }, errorTimeout);
      return;
    }
    setSpinner(true);
    setSpinnerText('Saving Processed statuses');
    try {
      const response = await saveProcessState(updating);
      if (!response.ok) {
        console.error(error);
        history.push(adminUrl, { message: 'Session expired. Please sign in to continue', level: AlertLevel.WARNING });
      }
      // Reset our state
      setUpdating({});
      setSpinner(false);
      // On Response, update our original data set to reflect the true status.
      fetchDonations();
    } catch (error) {
      console.error(error);
      history.push(adminUrl, { message: 'Session expired. Please sign in to continue', level: AlertLevel.WARNING });
    }
  }, [updating, history, adminUrl, error, fetchDonations]);

  /**
   * Handle form submission
   *
   * @param event
   */
  async function handleSearch(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (timeFrom > timeTo) {
      setErrorLevel(AlertLevel.ERROR);
      setError('Invalid date range.');
      setErrorDisplay(true);
      timer.current = setTimeout(() => {
        setErrorDisplay(false);
      }, errorTimeout);
    } else {
      setErrorDisplay(false);
      setSpinner(true);
      setSpinnerText('Loading results');
      await fetchDonations();
    }
  }

  /**
   * Should a donation be filtered out
   *
   * @param donation
   *
   * @return boolean
   */
  const filterDonation = useCallback((donation: Donation): Boolean => {
    if (
      (status === 'complete' && donation.donationStatus !== 'Complete') ||
      (status === 'error' && donation.donationStatus !== 'Error') ||
      (status === 'paid' && donation.donationStatus !== 'Paid') ||
      (status === 'unpaid' && donation.donationStatus !== 'Unpaid') ||
      (status === 'upcoming' && donation.donationStatus !== 'Upcoming') ||
      (processed === 'processed' && !donation.processed) ||
      (processed === 'unprocessed' && donation.processed)
    ) {
      return false;
    }
    return true;
  }, [processed, status]);

  const handleLegacyExport = useCallback(async () => {
    setSpinner(true);
    setSpinnerText('Preparing export');
    const headings = {
      date: {
        name: 'Date',
      },
      crn: {
        name: 'CRN',
      },
      donorType: {
        name: 'Donor Type',
      },
      orgName: {
        name: 'Organisation Name',
      },
      orgTitle: {
        name: 'Organisation Position Title',
      },
      title: {
        name: 'Title',
      },
      firstName: {
        name: 'Firstname',
      },
      middleName: {
        name: 'Middlename',
      },
      lastName: {
        name: 'Lastname',
      },
      address1: {
        name: 'Address Line 1',
      },
      address2: {
        name: 'Address Line 2',
      },
      suburb: {
        name: 'Suburb',
      },
      state: {
        name: 'State',
      },
      postcode: {
        name: 'Postcode',
      },
      country: {
        name: 'Country',
      },
      email: {
        name: 'Email',
      },
      phone: {
        name: 'Phone',
      },
      relationship: {
        name: 'Relationship',
      },
      amount: {
        name: 'Amount',
      },
      recurring: {
        name: 'Recurring',
      },
      recurringFrequency: {
        name: 'Recurring frequency',
      },
      fundId: {
        name: 'Fund ID',
      },
      area: {
        name: 'Area',
      },
      bequestContact: {
        name: 'Bequest contact',
      },
      oldDonor: {
        name: 'OLD Publish donor',
      },
      anonymous: {
        name: 'Anon Gift',
      },
      inspiration: {
        name: 'Inspiration',
      },
      paymentId: {
        name: 'Transaction number',
      },
      invoiceNumber: {
        name: 'Invoice number',
      },
      includedInWill: {
        name: 'Gift in Will',
      },
      appealId: {
        name: 'Appeal ID',
      },
    };

    const data = sortedResults.filter((donation) => filterDonation(donation)).map((donation) => {
      const donationObject = {
        date: dayjs(donation.createdAt).format('DD/MM/YYYY HH:mm:ss'),
        crn: donation.crn,
        donorType: 'Individual',
        orgName: '',
        orgTitle: '',
        title: donation.title,
        firstName: donation.firstName,
        middleName: '', // NA in new system, no place to capture currently
        lastName: donation.lastName,
        address1: donation.addressLine1,
        address2: donation.addressLine2,
        suburb: donation.suburb,
        state: donation.state,
        postcode: donation.postcode,
        country: countries.find((country) => country.iso3 === donation.country)?.name || '',
        email: donation.email,
        phone: `${donation.phonePrefix} ${donation.phone}`,
        relationship: donation.relationship,
        amount: donation.amount,
        recurring: donation.recurring ? 'Recurring' : '',
        recurringFrequency: donation.recurringPeriod === Donation.ONCE ? '' : donation.recurringPeriod,
        fundId: donation.fundId,
        area: donation.fundName,
        bequestContact: donation.bequestContact ? 'Yes' : 'No',
        oldDonor: '', // Legacy's legacy parameter
        anonymous: donation.anonymous ? 'ANON_GIFT' : 'PUBLISH',
        inspiration: donation.inspiration,
        paymentId: donation.receiptNumber,
        invoiceNumber: donation.invoiceNumber,
        includedInWill: donation.includedInWill ? 'Yes' : 'No',
        appealId: donation.appealId ?? '',
      };
      if (donation.companyName) {
        donationObject.donorType = donation.companyName.length ? 'Organisation' : 'Individual';
        donationObject.orgName = donation.companyName;
        donationObject.orgTitle = donation.companyPosition;
      }

      return donationObject;
    });

    const dataUri = createMultiArrayCSV(headings, data);
    setDownload(dataUri);
    const domElement = document.getElementById('downloadLink');
    setSpinner(false);
    setSpinnerText('');
    // Give the URL time to populate to prevent issues
    timer.current = setTimeout(() => {
      domElement?.click();
    }, 100);
  }, [sortedResults, filterDonation]);

  /**
   * Export the current data selection
   */
  const exportData = useCallback(async () => {
    setSpinner(true);
    setSpinnerText('Preparing export');

    const aliasConfig = await getConfig('aliases');

    const headings = await aliasConfig.json();

    const data = sortedResults.filter((donation) => filterDonation(donation)).map((donation: Donation) => {
      const donationObject = { ...donation };
      // @ts-ignore
      donationObject.createdAt = dayjs(donationObject.createdAt).format('DD/MM/YYYY HH:mm:ss');
      donationObject.responseAt = (donation.responseAt !== '' && typeof donation.responseAt !== 'undefined' ) ? dayjs(donation.responseAt).format('DD/MM/YYYY HH:mm:ss') : '';
      return donationObject;
    });

    const dataUri = createMultiArrayCSV(headings, data);
    setDownload(dataUri);
    const domElement = document.getElementById('downloadLink');
    setSpinner(false);
    setSpinnerText('');
    // Give the URL time to populate to prevent issues
    timer.current = setTimeout(() => {
      domElement?.click();
    }, 100);
  }, [sortedResults, filterDonation]);


  return (
    <div className="uq-layout-container admin-container">

      <h1>eDonations Administration - Donations</h1>
      <form className="margin-b1" onSubmit={event => handleSearch(event)}>
        {/* Alert for input errors */}
        <Alert level={errorLevel} message={error} display={errorDisplay} />

        <div className="uq-grid uq-grid--halves">
          <div className="uq-grid__col">
            <label htmlFor="timeFrom">From</label>
            <input
              type="date"
              id="timeFrom"
              name="timeFrom"
              value={timeFrom}
              onChange={(event) => setTimeFrom(event.target.value)}
            />
          </div>
          <div className="uq-grid__col">
            <label htmlFor="timeTo">To</label>
            <input
              type="date"
              id="timeTo"
              name="timeTo"
              value={timeTo}
              onChange={(event) => setTimeTo(event.target.value)}
            />
          </div>
        </div>

        <div className="grid-buttons">
          <button type="submit" className="uq-button" disabled={spinner}>Fetch data</button>
          <button type="button" className="uq-button uq-button--purple" disabled={spinner} onClick={handleLegacyExport}>Legacy Export</button>
        </div>
      </form>

      <div className="uq-grid uq-grid--quarters margin-t1">
        <div className="uq-grid__col">
          <label htmlFor="status">Donation Status</label>
          <select
            id="status"
            name="status"
            multiple={false}
            value={status}
            onChange={(event) => {setStatus(event.target.value)}}
          >
            <option value="all">All</option>
            <option value="paid">Paid</option>
            <option value="unpaid">Unpaid</option>
            <option value="complete">Complete</option>
            <option value="upcoming">Upcoming</option>
            <option value="error">Error</option>
          </select>
        </div>

        <div className="uq-grid__col">
          <label htmlFor="status">Processed Status</label>
          <select
            id="processed"
            name="processed"
            multiple={false}
            value={processed}
            onChange={(event) => {setProcessed(event.target.value)}}
          >
            <option value="all">All</option>
            <option value="processed">Processed Only</option>
            <option value="unprocessed">Unprocessed Only</option>
          </select>
        </div>

        <div className="uq-grid__col col-button">
          <button
            type="button"
            className="uq-button uq-button--purple"
            onClick={exportData}
            disabled={spinner}
          >Export current selection</button>
        </div>

        <div className="uq-grid__col col-button">
          <button className="uq-button" onClick={saveProcess} disabled={spinner}>Save Processed Status</button>
        </div>
      </div>
      <div className="uq-table__wrapper margin-t1">

        <LoadingSpinner display={spinner} spinnerMessage={spinnerText} messageVisible={true} />

        <table className="uq-table">
          <thead>
          <tr>
            <th>Fund ID</th>
            <th>Appeal ID</th>
            <th>CRN</th>
            <th>Amount</th>
            <th>Created At</th>
            <th>Donation Status</th>
            <th>Next Payment Due</th>
            <th>ResponseCode</th>
            <th>Processed</th>
          </tr>
          </thead>
          <tbody>
            {sortedResults.length !== 0 && sortedResults.filter(donation => filterDonation(donation)).map((donation: Donation) => {
              return <tr key={`${donation.identifier}`} className="uq-grid__col">
                <td>{donation.fundId}</td>
                <td>{donation.appealId}</td>
                <td>{donation.crn}</td>
                <td>{donation.amount}</td>
                <td>{new Date(donation.createdAt).toLocaleString()}</td>
                <td>{donation.donationStatus}</td>
                <td>{donation.recurring && !donation.paymentConfirmed ? dayjs(donation.identifier?.substring(0, 10)).format('DD/MM/YYYY') : ''}</td>
                <td>{donation.transactionCode}</td>
                <td>
                  <input
                    id={donation.identifier}
                    type="checkbox"
                    name={donation.identifier}
                    disabled={spinner}
                    // @ts-ignore
                    checked={checkedStates[String(donation.identifier)]}
                    onChange={(event) => {
                      setProcess(String(donation.identifier), event.target.checked)}
                    }
                  />
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
      <a id="downloadLink" className="hidden" download={`donations_${timeFrom}_${timeTo}_${status}_${processed}.csv`} target="_blank" rel="noreferrer" href={download}>Download</a>
    </div>
  );
}
