import React from 'react';
import { Link } from 'react-router-dom';

export default function Dashboard({ adminUrl }: { adminUrl: string }): JSX.Element {
  return (
    <>
      <div className="uq-layout-container">
        <div className="uq-grid__col">
          <h1>eDonations Administration</h1>
        </div>
      </div>

      <div className="uq-section uq-section--centered uq-section--shaded">
        <div className="uq-grid uq-grid--thirds">
          <div className="uq-grid__col">
            <div className="uq-card">
              <div className="uq-card__content-wrapper">
                <div className="uq-card__content">
                  <div className="uq-card__header">
                    <h3 className="uq-card__title">Donations</h3>
                  </div>
                  <div className="uq-card__body">
                    <p>View, manage and export donation information.</p>
                  </div>
                </div>
                <div className="uq-card__actions">
                  <Link
                    className="uq-button uq-button--purple"
                    to={`${adminUrl}/donations`}
                  >Donations</Link>
                </div>
              </div>
            </div>
          </div>

          <div className="uq-grid__col">
            <div className="uq-card">
              <div className="uq-card__content-wrapper">
                <div className="uq-card__content">
                  <div className="uq-card__header">
                    <h3 className="uq-card__title">Configure Aliases</h3>
                  </div>
                  <div className="uq-card__body">
                    <p>View and manage form alias settings. These are then utilised as headings on export information.</p>
                  </div>
                </div>
                <div className="uq-card__actions">
                  <Link
                    className="uq-button uq-button--purple"
                    to={`${adminUrl}/config/alias`}
                  >Configure Aliases</Link>
                </div>
              </div>
            </div>
          </div>

          <div className="uq-grid__col">
            <div className="uq-card">
              <div className="uq-card__content-wrapper">
                <div className="uq-card__content">
                  <div className="uq-card__header">
                    <h3 className="uq-card__title">Configure Titles</h3>
                  </div>
                  <div className="uq-card__body">
                    <p>View and manage titles that are available to the users.</p>
                  </div>
                </div>
                <div className="uq-card__actions">
                  <Link
                    className="uq-button uq-button--purple"
                    to={`${adminUrl}/config/title`}
                  >Configure Titles</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
