import { History } from 'history';
import React, { useCallback, useEffect, useState } from 'react';
import { AlertLevel } from '../Alert/Alert';
import { handleSubmitFunction, loadData } from './config';

const id = 'aliases';
const fields: { [key: string]: {name: string, exportName: string}; } = {
  createdAt: {
    name: 'Time of confirmation',
    exportName: '',
  },
  donationStatus: {
    name: 'Current Status',
    exportName: '',
  },
  fundId: {
    name: 'Fund ID',
    exportName: '',
  },
  fundName: {
    name: 'Fund Short Name',
    exportName: '',
  },
  appealId: {
    name: 'Appeal ID',
    exportName: '',
  },
  amount: {
    name: 'Donation Amount',
    exportName: '',
  },
  anonymous: {
    name: 'Remain Anonymous',
    exportName: '',
  },
  recurring: {
    name: 'Recurring Payment',
    exportName: '',
  },
  recurringPeriod: {
    name: 'Recurring Period',
    exportName: '',
  },
  paymentConfirmed: {
    name: 'Payment Confirmed',
    exportName: '',
  },
  invoiceNumber: {
    name: 'Invoice Number',
    exportName: '',
  },
  receiptNumber: {
    name: 'Receipt Number',
    exportName: '',
  },
  transactionCode: {
    name: 'Transaction Code',
    exportName: '',
  },
  responseAt: {
    name: 'Payment Gateway Response Received at',
    exportName: '',
  },
  emailReceiptStatus: {
    name: 'Email Receipt Status',
    exportName: '',
  },
  templateName: {
    name: 'Email Template Used',
    exportName: '',
  },
  processed: {
    name: 'Processed',
    exportName: '',
  },
  // Personal Details
  crn: {
    name: 'CRN',
    exportName: '',
  },
  companyName: {
    name: 'Organisation Name',
    exportName: '',
  },
  companyABN: {
    name: 'Organisation ABN',
    exportName: '',
  },
  companyPosition: {
    name: 'Organisation Position Title',
    exportName: '',
  },
  title: {
    name: 'Title',
    exportName: '',
  },
  firstName: {
    name: 'Given Name',
    exportName: '',
  },
  lastName: {
    name: 'Surname',
    exportName: '',
  },
  email: {
    name: 'Email',
    exportName: '',
  },
  emailValidated: {
    name: 'Email Experian Validated',
    exportName: '',
  },
  phonePrefix: {
    name: 'Phone Country Prefix',
    exportName: '',
  },
  phone: {
    name: 'Phone',
    exportName: '',
  },
  phoneValidated: {
    name: 'Phone Experian Validated',
    exportName: '',
  },
  // Address
  addressLine1: {
    name: 'Address line 1',
    exportName: '',
  },
  addressLine2: {
    name: 'Address line 2',
    exportName: '',
  },
  addressLine3: {
    name: 'Address line 3',
    exportName: '',
  },
  suburb: {
    name: 'Suburb',
    exportName: '',
  },
  state: {
    name: 'State',
    exportName: '',
  },
  postcode: {
    name: 'Postcode',
    exportName: '',
  },
  country: {
    name: 'Country',
    exportName: '',
  },
  addressValidated: {
    name: 'Address Experian Validated',
    exportName: '',
  },
  bequestContact: {
    name: 'Contact about bequest',
    exportName: '',
  },
  includedInWill: {
    name: 'Gift included in will',
    exportName: '',
  },
  relationship: {
    name: 'Relationship to UQ',
    exportName: '',
  },
  inspiration: {
    name: 'What inspired you to give',
    exportName: '',
  },
};

export default function ConfigAlias(
  { setAlertLevel, setAlertMessage, setAlertDisplay, setAlertSpinner, adminUrl, history }:
    {
      setAlertLevel: React.Dispatch<React.SetStateAction<AlertLevel>>,
      setAlertMessage: React.Dispatch<React.SetStateAction<string>>,
      setAlertDisplay: React.Dispatch<React.SetStateAction<boolean>>,
      setAlertSpinner: React.Dispatch<React.SetStateAction<boolean>>,
      adminUrl: string,
      history: History,
    }
): JSX.Element {
  const [data, setData] = useState(fields);
  const [submitting, setSubmitting] = useState<boolean>(false);

  /**
   * Handle a value change on the form
   * This prevents us needing to set a state for each individual item.
   *
   * @param event
   */
  const handleChange = useCallback((event) => {
    setData(function(data) {
      return {
        ...data,
        [event.target.name]: {
          name: document.getElementById(event.target.name)?.getAttribute('placeholder'),
          exportName: event.target.value,
        }
      };
    });
  }, []);

  /**
   * Handle form submission, prevent default, save to our API, display loading, etc.
   *
   * @param event
   */
  const handleSubmit = useCallback((event) => {
    setSubmitting(true);
    handleSubmitFunction({
      event,
      setAlertLevel,
      setAlertMessage,
      setAlertDisplay,
      setAlertSpinner,
      history,
      adminUrl,
      id,
      data,
    }).then(() => setSubmitting(false));
  }, [history, data, adminUrl, setAlertDisplay, setAlertSpinner, setAlertMessage, setAlertLevel]);

  /**
   * load the initial data via callback to prevent loops on data change
   */
  const loadInitialData = useCallback(() => {
    setSubmitting(true);
    loadData({
      setAlertLevel,
      setAlertMessage,
      setAlertDisplay,
      setAlertSpinner,
      history,
      adminUrl,
      id,
      setData,
      data,
    }).then(() => {
      setSubmitting(false);
    });
  }, [history, adminUrl, setAlertDisplay, setAlertSpinner, setAlertMessage, setAlertLevel]);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  return (
    <>
      <div className="uq-layout-container admin-container">
        <h1>eDonations Administration - Aliases</h1>

        <form onSubmit={event => handleSubmit(event)}>
          <div className="grid-buttons margin-b1">
            <button type="submit" className="uq-button" disabled={submitting}>Save</button>
          </div>

          <div className="uq-table__wrapper">

            <table className="uq-table admin-table">
              <thead>
              <tr>
                <th>Default Name</th>
                <th>Alias</th>
              </tr>
              </thead>
              <tbody>
              { Object.entries(data).map(([key, value]) => {
                return <tr key={`${key}`}>
                  <td>{value.name}</td>
                  <td>
                    <input
                      id={key}
                      type="text"
                      name={key}
                      className="table-input"
                      placeholder={value.name}
                      value={value.exportName}
                      onChange={event => handleChange(event)}
                    />
                  </td>
                </tr>
              })}
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </>
  );
}
