import { History } from 'history';
import React from 'react';
import { getConfig, setConfig } from '../../API';
import { AlertLevel } from '../Alert/Alert';

export interface DataProps {
  setAlertLevel: React.Dispatch<React.SetStateAction<AlertLevel>>;
  setAlertMessage: React.Dispatch<React.SetStateAction<string>>;
  setAlertDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertSpinner: React.Dispatch<React.SetStateAction<boolean>>;
  history: History;
  adminUrl: string;
  id: string;
  data: any;
}

export interface SubmitProps extends DataProps {
  event: React.FormEvent<HTMLFormElement>;
}

export interface LoadProps extends DataProps {
  setData: any;
}

export const loadData = async (loadProps: LoadProps) => {
  loadProps.setAlertLevel(AlertLevel.INFO);
  loadProps.setAlertMessage('Loading configuration...');
  loadProps.setAlertSpinner(true);
  loadProps.setAlertDisplay(true);
  try {
    const response = await getConfig(loadProps.id);
    if (response.ok) {
      const data = await response.json();
      // Combine our default set with data pulled from the DB
      loadProps.setData({...loadProps.data, ...JSON.parse(data)});
    } else if (response.status === 403) {
      loadProps.history.push(loadProps.adminUrl, { message: 'Session expired. Please sign in to continue', level: AlertLevel.WARNING });
    }
    loadProps.setAlertDisplay(false);
  } catch (error) {
    loadProps.setAlertLevel(AlertLevel.ERROR);
    loadProps.setAlertMessage('Unable to connect to API. Please refresh your page or try again later. If the problem persists please contact its.softwareservices@uq.edu.au');
    loadProps.setAlertDisplay(true);
    loadProps.setAlertSpinner(false);
  }
};

/**
 * Handle form submission, prevent default, save to our API, display loading, etc.
 *
 * @param submitProps
 *
 * @return Promise<void>
 */
export const handleSubmitFunction = async (submitProps: SubmitProps): Promise<void> => {
  submitProps.event.preventDefault();
  submitProps.setAlertLevel(AlertLevel.INFO);
  submitProps.setAlertMessage('Saving...');
  submitProps.setAlertSpinner(true);
  submitProps.setAlertDisplay(true);
  // Post to API
  try {
    const response = await setConfig(submitProps.id, submitProps.data);
    if (response.ok) {
      submitProps.setAlertLevel(AlertLevel.SUCCESS);
      submitProps.setAlertMessage('Updates have been saved.');
      submitProps.setAlertSpinner(false);
      // Hide our message after 5 seconds
      setTimeout(() => { submitProps.setAlertDisplay(false) }, 5000);
    } else if (response.status === 403) {
      submitProps.history.push(submitProps.adminUrl, { message: 'Session expired. Please sign in to continue', level: AlertLevel.WARNING });
    }
  } catch (error) {
    submitProps.setAlertLevel(AlertLevel.ERROR);
    submitProps.setAlertMessage('Unable to connect to API. Please refresh your page or try again later. If the problem persists please contact its.softwareservices@uq.edu.au');
    submitProps.setAlertDisplay(true);
    submitProps.setAlertSpinner(false);
  }
};
