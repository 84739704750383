import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { addressSearch, addressValidation } from '../../API';
import { Donation, paramsToLower, periodToString } from '../../classes/Donation';
import { Fund } from '../../classes/Fund';
import { Metrics } from '../../classes/Metrics';
import { countries, Country, inputError, inputSuccess, international } from '../../Helpers';
import { ExperianAddressResult, ExperianSuggestion } from '../../interfaces';
import Alert, { AlertLevel } from "../Alert/Alert";

export default function Address(
  { donation, fund, setDonation, metrics, setAppealId }:
    {
    donation: Donation,
    fund: Fund,
    setDonation: React.Dispatch<React.SetStateAction<Donation>>,
    metrics: Metrics,
    setAppealId: React.Dispatch<React.SetStateAction<string>>
  }
) {
  // sentinel for using entered address as is
  const ENTERED_ADDRESS = '*UQ';

  // internal state management for address data
  const [addressCountry, setAddressCountry] = useState<string>(donation.country ?? 'AUS');
  const [addressText, setAddressText] = useState<string>('');
  const [addressLine1, setAddressLine1] = useState<string>(donation.addressLine1);
  const [addressLine2, setAddressLine2] = useState<string>(donation.addressLine2);
  const [addressLine3, setAddressLine3] = useState<string>(donation.addressLine3);
  const [addressSuburb, setAddressSuburb] = useState<string>(donation.suburb);
  const [addressState, setAddressState] = useState<string>(donation.state);
  const [addressPostcode, setAddressPostcode] = useState<string>(donation.postcode);
  const [addressSearchList, setAddressSearchList] = useState<Array<ExperianSuggestion>>([]);
  const [addressValidated, setAddressValidated] = useState<boolean>(donation.addressValidated);
  const [addressCountry2, setAddressCountry2] = useState<string>(donation.country2 ?? 'AU');
  const [loading, setLoading] = useState<boolean>(false);

  // @ts-ignore
  let { fundUrl, donationAmount } = useParams();
  const history = useHistory();
  const countriesRequiringState = ['AUS', 'CAN', 'USA'];

  const setFullAddress = (line1: string, line2: string, line3: string, suburb: string, state: string, postcode: string) => {
    setAddressLine1(line1);
    setAddressLine2(line2);
    setAddressLine3(line3);
    setAddressSuburb(suburb);
    setAddressState(state);
    setAddressPostcode(postcode);
  }

  const [internationalCountry, setInternationalCountry] = useState<Country|undefined>(undefined);

  // Internal state management fields for UI
  const [addressMessage, setAddressMessage] = useState<string>('');
  const [allowAddressEdit, setAllowAddressEdit] = useState<boolean>(false || Boolean(donation.addressLine1.length && donation.suburb.length));
  const [searchFocused, setSearchFocused] = useState<boolean>(false);
  const [showAddressFields, setShowAddressFields] = useState<boolean>(donation.addressValidated || Boolean(donation.addressLine1.length && donation.suburb.length));
  const [addressCountryError, setAddressCountryError] = useState<string>('');
  const [addressLine1Error, setAddressLine1Error] = useState<string>('');
  const [addressSuburbError, setAddressSuburbError] = useState<string>('');
  const [addressPostcodeError, setAddressPostcodeError] = useState<string>('');
  const [addressStateError, setAddressStateError] = useState<string>('');
  const [addressSearchError, setAddressSearchError] = useState<string>('');
  const [phonePrefix, setPhonePrefix] = useState<string>('');
  const [searching, setSearching] = useState<boolean>(false);

  const errorClass = 'uq-error-message';
  const successClass = 'uq-input--large uq-input--success';
  const linkData = {
    event: 'Custom click',
    click_category: 'UQ giving donation app',
  };

  const countryError = 'Please select your country.';
  const address1Error = 'Please include your street address of 4 or more characters.';
  const postcodeError = 'Please include your postcode of 4 or more characters.';
  const suburbError = 'Please include your suburb, city or province of 2 or more characters.';
  const stateError = 'Please include your state or region of 2 or more characters.';

  const onSelectedAddressChanged = async (selection: ExperianSuggestion) => {
    // clear any address field error messages from previous attempted submissions
    setAddressLine1Error('');
    setAddressSuburbError('');
    setAddressPostcodeError('');
    setAddressStateError('');

    // show fields
    setAllowAddressEdit(false);
    setAddressMessage('');
    setAddressSearchError('');
    setLoading(true);

    // are we the entered one?
    if (selection.global_address_key === ENTERED_ADDRESS) {
      metrics.pushData({
        ...linkData,
        click_action: 'Manual Address',
        click_label: 'Enter Address Manually',
        click_url: undefined,
      });

      // fill in manual entry fields
      setFullAddress(addressText, '', '', '', '', '');
      setAllowAddressEdit(true);
      setShowAddressFields(true);
      setAddressValidated(false);
    } else {
      // process it
      try {
        const response = await addressValidation(selection.global_address_key);
        if (!response.ok) {
          // Throw to be caught straight after to prevent logic duplication of the error handling
          throw new Error(response.statusText);
        }
        const data = await response.json();
        const result: ExperianAddressResult = data.response.result;
        setFullAddress(result.address.address_line_1, result.address.address_line_2, result.address.address_line_3, result.address.locality, result.address.region, result.address.postal_code);
        setShowAddressFields(true);
        setAddressValidated(true);
        metrics.pushData({
          ...linkData,
          click_action: 'Validated Address',
          click_label: 'Select your address',
          click_url: undefined,
        });
      } catch(error) {
        console.error('Error', error);
        setLoading(false);
        setFullAddress(addressText, '', '', '', '', '');
        setAddressText('');
        setAddressMessage('Address search failed - please enter manual address');
        setAllowAddressEdit(true);
        setShowAddressFields(true);
        setAddressValidated(false);
      }
    }
    // hide list now as entry processed
    setSearchFocused(false);
    setLoading(false);
  }

  /**
   * Validate form and provide error messages if data is not correct.
   */
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if(!addressCountry || addressCountry === '-') {
      setAddressCountryError(countryError);
      return;
    }
    setAddressCountryError('');

    if (!showAddressFields) {
      setAddressSearchError('Please enter your address');
      return;
    }
    setAddressSearchError('');

    let error = false;

    // Skip validation checks on an Experian validated address
    if (!addressValidated) {
      if (addressLine1.length < 4) {
        setAddressLine1Error(address1Error);
        error = true;
      }
      if (addressSuburb.length < 2) {
        setAddressSuburbError(suburbError);
        error = true;
      }
      if (addressPostcode.length < 4) {
        setAddressPostcodeError(postcodeError);
        error = true;
      }
      if (addressState.length < 2 && countriesRequiringState.includes(addressCountry)) {
        setAddressStateError(stateError);
        error = true;
      }
    }

    if (!error) {
      // Mirror the existing donor
      const donationObject = donation;
      // Update address details
      donationObject.phonePrefix = phonePrefix;
      donationObject.addressLine1 = addressLine1;
      donationObject.addressLine2 = addressLine2;
      donationObject.addressLine3 = addressLine3;
      donationObject.suburb = addressSuburb;
      donationObject.state = addressState;
      donationObject.postcode = addressPostcode;
      donationObject.country = addressCountry;
      donationObject.country2 = addressCountry2;
      donationObject.addressValidated = addressValidated;
      donationObject.amount = donationAmount;

      // Reset as the donor object.
      // This handles new, back buttons and a secondary donation after completion
      setDonation(donationObject);

      metrics.pushData({
        ...linkData,
        click_action: 'Step click',
        click_label: 'Next',
        click_url: `${window.location.origin}/confirm`,
      });
      history.push('/confirm');
    }
  }

  const searchForAddress = useCallback( async (text, addressCountry) => {
    const response = await addressSearch(text.replace(/[/?+&]/g, ' '), addressCountry);
    setShowAddressFields(false);
    setAllowAddressEdit(false);
    setAddressMessage('');
    setAddressSearchError('');
    try {
      if (! response.ok) {
        // Throw to be caught straight after to prevent logic duplication of the error handling
        throw new Error(response.statusText);
      }
      const data = await response.json();
      let suggestions: ExperianSuggestion[] = data.response.result.suggestions;

      suggestions.push({
        format: '',
        global_address_key: ENTERED_ADDRESS,
        matched: [],
        text: 'Enter Address Manually',
      });

      setAddressSearchList(suggestions);
      setSearching(false);
    } catch (error) {
      // Something happened in setting up the request that triggered an Error
      console.error('Error', error);
      setSearching(false);
      // handle as manually entered since validation call failed
      setFullAddress(addressText, '', '', '', '', '');
      setAddressText('');
      setAddressMessage('Address search failed - please enter manual address');
      setAllowAddressEdit(true);
      setShowAddressFields(true);
      setAddressValidated(false);
    }
  }, [addressText]);

  useEffect(() => {
    // Sanity checks
    if (!fundUrl) {
      history.push(`/`);
    } else if (!donationAmount) {
      history.push(`/${fundUrl}`);
    }
    // @ts-ignore
    document.getElementById('content').scrollIntoView({
      behavior: 'smooth'
    });
    const lowerCaseParams = paramsToLower(history);
    if (lowerCaseParams.has('appeal_id')) {
      setAppealId(lowerCaseParams.get('appeal_id') || '');
    }

    const debounce = setTimeout(() => {
      const text = addressText.trim() ?? '';

      if (text.length >= 4) {
        setSearching(true);
        searchForAddress(text, addressCountry);
      }
    }, 500);

    return () => clearTimeout(debounce);
  }, [donationAmount, addressText, addressCountry, fundUrl, history, searchForAddress, setAppealId]);

  return (
    <div className="uq-grid uq-grid--golden">
      <div className="uq-grid__col">
        <span className="fund-steps">Step 3 of 4</span>
        <h1 className="gift-heading">My gift to {fund.friendlyName} of ${donationAmount}
          { donation.recurring ? ` every ${periodToString(donation.recurringPeriod)}` : '' }
        </h1>
        <h2 className="step-heading">Please enter the billing address for your payment method</h2>
        <form className="donation-form" onSubmit={event => handleSubmit(event)}>

          <label htmlFor="country">Country:</label>
          <select
            id="country"
            name="country"
            multiple={false}
            value={addressCountry}
            onChange={(event) => {
              setAddressCountry(event.target.value);
              setAddressCountry2(String(event.target.options[event.target.selectedIndex].dataset.iso2));
              setPhonePrefix(String(event.target.options[event.target.selectedIndex].dataset.ext));
              setInternationalCountry(international[String(event.target.options[event.target.selectedIndex].dataset.iso2)]);
              metrics.pushData({
                ...linkData,
                click_action: 'Country',
                click_label: event.target.value,
                click_url: undefined
              })
            }}
            onBlur={(event) => {
              const isInvalidCountry = (!addressCountry || addressCountry === '-')
              if (isInvalidCountry) {
                inputError(undefined, "country");
                setAddressCountryError(countryError);
              } else {
                inputSuccess(undefined, "country");
                setAddressCountryError('');
              }
            }}
            aria-describedby="countryFeedback"
          >
            <option key='-' value="-">Select a country...</option>
            {countries.map(o => <option key={o.iso3} value={o.iso3} data-ext={o.ext} data-iso2={o.iso2}>{o.name}</option>)}
          </select>
          <span id="countryFeedback"
                className={addressCountryError.length ? errorClass : ''}
                aria-live="polite">
               {addressCountryError.length ? addressCountryError : ''}
           </span>

          <div className="address-search">
            <label htmlFor="addressSearch">Address Search:</label>
            <input
              placeholder="Search for an address"
              className={searching ? "uq-input--large search--loading" : "uq-input--large"}
              type="text"
              id="addressSearch"
              name="addressSearch"
              value={addressText}
              // disable automatic corrections by the browsers for complete, correction, capitalise and spell checking
              autoComplete="new-password" // new-password to prevent all browser helpers
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              onChange={(event) => setAddressText(event.target.value)}
              onFocus={() => setSearchFocused(true)}
              onBlur={() => { setTimeout(() => {setSearchFocused(false);}, 10); }}
              aria-describedby="addressSearchFeedback"
            />
            <span id="addressSearchFeedback"
              className={(addressSearchError.length || addressMessage.length) ? errorClass : ''}
              aria-live="polite">
                {addressSearchError || addressMessage}
            </span>
            {searching &&
            <div className="uq-loading-spinner address-search__spinner" role="alert" aria-live="assertive">
              <span>Searching...</span>
            </div>
            }

            {addressSearchList && addressSearchList.length && searchFocused ?
              <ul className="fund-search-list address-list">
                {addressSearchList.map((address) => {
                  return (
                    <li
                      className="fund-search-list__item"
                      key={address.global_address_key}
                      onMouseDownCapture={() => onSelectedAddressChanged(address) }
                      >{address.text}
                    </li>
                  );
                })}
              </ul> : ''
            }
          </div>
          {loading &&
            <div className="uq-loading-spinner" role="alert" aria-live="assertive">
              <span>Loading...</span>
            </div>
          }

          {showAddressFields &&
            <div>
              <label htmlFor="addressLine1">Address line 1:</label>
              <input
                className={addressValidated ? successClass : 'uq-input--large'}
                type="text"
                id="addressLine1"
                name="addressLine1"
                value={addressLine1}
                disabled={addressValidated}
                onChange={event => (allowAddressEdit ? setAddressLine1(event.target.value) : "") }
                onBlur={(event) => {
                  if (addressLine1.length >= 4) {
                    inputSuccess(event);
                    setAddressLine1Error('');
                  } else {
                    inputError(event);
                    setAddressLine1Error(address1Error);
                  }
                }}
                aria-describedby="addressLine1Feedback"
              />
              <span id="addressLine1Feedback"
                className={addressLine1Error ? errorClass : ''}
                aria-live="polite">
                  {addressLine1Error.length ? addressLine1Error : ''}
              </span>

              {allowAddressEdit || (addressValidated && addressLine2.length) ?
                <>
                  <label htmlFor="addressLine2">Address line 2:</label>
                  <input
                    className={addressValidated ? successClass : 'uq-input--large'}
                    type="text"
                    id="addressLine2"
                    name="addressLine2"
                    value={addressLine2}
                    disabled={addressValidated}
                    onChange={event => (allowAddressEdit ? setAddressLine2(event.target.value) : "")}
                    onBlur={(event) => {
                      inputSuccess(event);
                    }}
                    aria-describedby="addressLine2Feedback"
                  />
                  <span id="addressLine2Feedback"
                    className={''}
                    aria-live="polite"
                  >
                      {''}
                  </span>
                </>
              : ''}

              {allowAddressEdit || (addressValidated && addressLine3.length) ?
                <>
                  <label htmlFor="addressLine3">Address line 3:</label>
                  <input
                    className={addressValidated ? successClass : 'uq-input--large'}
                    type="text"
                    id="addressLine3"
                    name="addressLine3"
                    value={addressLine3}
                    disabled={addressValidated}
                    onChange={event => (allowAddressEdit ? setAddressLine3(event.target.value) : "") }
                    onBlur={(event) => {
                      inputSuccess(event);
                    }}
                    aria-describedby="addressLine3Feedback"
                  />

                  <span id="addressLine3Feedback"
                    className={''}
                    aria-live="polite">
                      {''}
                  </span>
                </>
              : ''}

              {allowAddressEdit || (addressValidated && addressSuburb.length) ?
                <>
                  <label htmlFor="addressSuburb">Suburb:</label>
                  <input
                    className={addressValidated ? successClass : 'uq-input--large'}
                    type="text"
                    id="addressSuburb"
                    name="addressSuburb"
                    value={addressSuburb}
                    disabled={addressValidated}
                    onChange={event => (allowAddressEdit ? setAddressSuburb(event.target.value) : "") }
                    onBlur={(event) => {
                      if (addressSuburb.length >= 2) {
                        inputSuccess(event);
                        setAddressSuburbError('');
                      } else {
                        inputError(event);
                        setAddressSuburbError(suburbError);
                      }
                    }}
                    aria-describedby="addressSuburbFeedback"
                  />
                  <span id="addressSuburbFeedback"
                    className={addressSuburbError ? errorClass : ''}
                    aria-live="polite">
                      {addressSuburbError.length ? addressSuburbError : ''}
                  </span>
                </>
              : ''}

              {allowAddressEdit || (addressValidated && addressState.length) ?
                <>
                  <label htmlFor="addressState">State:</label>
                  <input
                    className={addressValidated ? successClass : 'uq-input--large'}
                    type="text"
                    id="addressState"
                    name="addressState"
                    value={addressState}
                    disabled={addressValidated}
                    onChange={event => (allowAddressEdit ? setAddressState(event.target.value) : "") }
                    onBlur={(event) => {
                      if (addressState.length >= 2 || !countriesRequiringState.includes(addressCountry)) {
                        inputSuccess(event);
                        setAddressStateError('');
                      } else {
                        inputError(event);
                        setAddressStateError(stateError);
                      }
                    }}
                    aria-describedby="addressStateFeedback"
                  />
                  <span id="addressStateFeedback"
                    className={addressStateError ? errorClass : ''}
                    aria-live="polite">
                      {addressStateError.length ? addressStateError : ''}
                  </span>
                </>
              : '' }

              {allowAddressEdit || (addressValidated && addressPostcode.length) ?
                <>
                  <label htmlFor="addressPostcode">Post code:</label>
                  <input
                    className={addressValidated ? successClass : 'uq-input--large'}
                    type="text"
                    id="addressPostcode"
                    name="addressPostcode"
                    value={addressPostcode}
                    disabled={addressValidated}
                    onChange={event => (allowAddressEdit ? setAddressPostcode(event.target.value) : "") }
                    onBlur={(event) => {
                      if (addressPostcode.length >= 4) {
                        inputSuccess(event);
                        setAddressPostcodeError('');
                      } else {
                        inputError(event);
                        setAddressPostcodeError(postcodeError);
                      }
                    }}
                    aria-describedby="addressPostcodeFeedback"
                  />

                  <span id="addressPostcodeFeedback"
                    className={addressPostcodeError ? errorClass : ''}
                    aria-live="polite">
                      {addressPostcodeError.length ? addressPostcodeError : ''}
                  </span>
                </>
              : '' }
            </div>
          }

          <div className="grid-buttons">
            <button type="submit" className="uq-button">Next</button>
            <Link to={`/${fundUrl}/${donationAmount}`} className="uq-button uq-button--text" onClick={() => {
              metrics.pushData({
                ...linkData,
                click_action: 'Step click',
                click_label: 'Back',
                click_url: `${window.location.origin}/${fundUrl}/${donationAmount}`
              });
            }}>Back</Link>
          </div>

          <Alert
            container={true}
            level={AlertLevel.INFO}
            message={
              <>
                <p>If you experience any issues processing your donation, please call UQ Advancement and Community
                  Engagement team on<a href="tel:+61733463909" style={{marginLeft: '5px'}}>07 3346 3909</a> and one
                  of our team members will be happy to assist.</p>
                <p>Thank you for your support.</p>
              </>
            }
            display={true}
          />
        </form>
      </div>
      <div className="uq-grid__col">
        {internationalCountry &&
          <div className="uq-section uq-section--centered pt-0">
            <div className="uq-section__container">
              <div className="uq-section__content">
                <h2>Donating from {internationalCountry.name}</h2>
                <p>{internationalCountry.text}</p>
                <p>{internationalCountry.cta}</p>
                <a href={internationalCountry.url} className="uq-button">YES - Donate here</a>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}
