import { History } from 'history';

const pending = 'PENDING';
const sent = 'SENT';
const sendError = 'SEND ERROR';

const ONCE = 'once';
const RECURRING = 'recurring';
const FORTNIGHTLY = 'fortnightly';
const MONTHLY = 'monthly';
const QUARTERLY = 'quarterly';
const BIANNUALLY = 'biannually';
const ANNUALLY = 'annually'

export enum DonationStatus {
  COMPLETE = 'Complete', // Complete - Paid and email receipts have been sent
  ERROR = 'Error',       // An error has occurred with the payment
  PAID = 'Paid',         // Successful payment confirmed by payment gateway
  UNPAID = 'Unpaid',     // Submitted to payment gateway, no confirmation
  UPCOMING = 'Upcoming', // Future recurring transaction
}

export enum Relationship {
  ALUMNI = 'UQ Alumnus',
  STUDENT = 'UQ Student',
  STAFF = 'UQ Staff',
  PARENT = 'Parent',
  FRIEND = 'Friend of the University',
  ORG = 'Organisational support',
  OTHER = 'Other',
}

export const PRESET_RELATIONS: string[] = [
  Relationship.ALUMNI,
  Relationship.STUDENT,
  Relationship.STAFF,
  Relationship.PARENT,
  Relationship.FRIEND,
  Relationship.ORG,
]

export function relationToString(relationship: Relationship): string {
  switch (relationship) {
    case Relationship.ALUMNI: return 'Alumni';
    case Relationship.STUDENT: return 'Student';
    case Relationship.STAFF: return 'Staff';
    case Relationship.PARENT: return 'Parent';
    case Relationship.FRIEND: return 'Community Supporter';
    case Relationship.ORG: return 'Organisational Support';
    case Relationship.OTHER: return 'Other';
  }
}

/**
 * Convert the period adjective to a noun, to make sense in user context
 * e.g. fortnightly becomes fortnight
 *
 * @param period
 * @return string
 */
export function periodToString(period: string): string {
  switch (period) {
    case Donation.FORTNIGHTLY:
      return 'fortnight';
    case Donation.MONTHLY:
      return 'month';
    case Donation.ANNUALLY:
      return 'year';
    default:
      return '';
  }
}

/**
 * Convert the Url parameters keys to lowercase for reliable usage and prevent case sensitivity issues
 *
 * @param history
 *
 * @return URLSearchParams
 */
export function paramsToLower(history: History): URLSearchParams {
  const urlParams = new URLSearchParams(history.location.search);
  return new URLSearchParams([...urlParams].map(([key, value]) => [key.toLowerCase(), value]));
}

/**
 * Donation
 */
export class Donation {
  identifier?: string;
  createdAt: string;
  fundId: string;
  fundName: string;
  templateName: string;
  amount: number;
  appealId?: string;
  anonymous: boolean;
  recurring: boolean;
  recurringPeriod: string;
  paymentConfirmed: boolean;
  invoiceNumber: string;
  receiptNumber: string;
  transactionCode: string;
  responseAt: string;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  emailValidated: boolean;
  phonePrefix: string;
  phone: string;
  phoneValidated: boolean;
  bequestContact: boolean;
  companyName: string;
  companyABN: string;
  companyPosition: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;
  country2: string;
  addressValidated: boolean;
  processed: boolean;
  emailReceiptStatus: string;
  donationStatus?: string;
  chartString?: string;
  debugValue?: string;
  crn: string;
  fundUrl?: string;
  includedInWill: boolean;
  relationship: string;
  inspiration: string;
  captcha?: string;

  static get pending() {
    return pending;
  }

  static get sent() {
    return sent;
  }

  static get sendError() {
    return sendError;
  }

  static get PERMITTED_FREQUENCY() {
    return {
      ONCE,
      FORTNIGHTLY,
      MONTHLY,
      // QUARTERLY,
      // BIANNUALLY,
      ANNUALLY,
    };
  }

  static get RECURRING_PERIODS() {
    return {
      FORTNIGHTLY,
      MONTHLY,
      // QUARTERLY,
      // BIANNUALLY,
      ANNUALLY,
    };
  }

  static get ONCE() {
    return ONCE;
  }

  static get RECURRING() {
    return RECURRING;
  }

  static get FORTNIGHTLY() {
    return FORTNIGHTLY;
  }

  static get MONTHLY() {
    return MONTHLY;
  }

  static get QUARTERLY() {
    return QUARTERLY;
  }

  static get BIANNUALLY() {
    return BIANNUALLY;
  }

  static get ANNUALLY() {
    return ANNUALLY;
  }

  constructor(
    identifier = '',
    createdAt = new Date().toISOString(),
    fundId = '',
    fundName = '',
    templateName = '',
    amount = 0,
    recurring = false,
    paymentConfirmed = false,
    appealId = '',
    anonymous = true,
    recurringPeriod = '',
    invoiceNumber = '',
    receiptNumber = '',
    transactionCode = '',
    responseAt = '',
    title = '',
    firstName = '',
    lastName = '',
    email = '',
    emailValidated = false,
    phonePrefix = '',
    phone = '',
    phoneValidated= false,
    bequestContact = false,
    companyName =  '',
    companyABN = '',
    companyPosition = '',
    addressLine1 = '',
    addressLine2 = '',
    addressLine3 = '',
    suburb = '',
    state = '',
    postcode = '',
    country = '',
    country2 = '',
    addressValidated = false,
    processed = false,
    emailReceiptStatus = pending,
    crn = '',
    fundUrl = '',
    includedInWill = false,
    relationship = '',
    inspiration = ''
  ) {
    this.identifier = identifier;
    this.createdAt = createdAt;
    this.fundId = fundId;
    this.fundName = fundName;
    this.templateName = templateName;
    this.amount = amount;
    this.appealId = appealId;
    this.anonymous = anonymous;
    this.recurring = recurring;
    this.recurringPeriod = recurringPeriod;
    this.paymentConfirmed = paymentConfirmed;
    this.invoiceNumber = invoiceNumber;
    this.receiptNumber = receiptNumber;
    this.transactionCode = transactionCode;
    this.responseAt = responseAt;
    this.title = title;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.emailValidated = emailValidated;
    this.phonePrefix = phonePrefix;
    this.phone = phone;
    this.phoneValidated = phoneValidated;
    this.bequestContact = bequestContact;
    this.companyName = companyName;
    this.companyABN = companyABN;
    this.companyPosition = companyPosition;
    this.addressLine1 = addressLine1;
    this.addressLine2 = addressLine2;
    this.addressLine3 = addressLine3;
    this.suburb = suburb;
    this.state = state;
    this.postcode = postcode;
    this.country = country;
    this.country2 = country2;
    this.addressValidated = addressValidated;
    this.processed = processed;
    this.emailReceiptStatus = emailReceiptStatus;
    this.crn = crn;
    this.fundUrl = fundUrl;
    this.includedInWill = includedInWill;
    this.relationship = relationship;
    this.inspiration = inspiration;
  }
}
