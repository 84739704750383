export class Metrics {
  gtmInitiated: boolean

  constructor() {
    this.gtmInitiated = false;
  }

  initGTM(gtmCode: string) {
    if (gtmCode.length < 6) {
      console.error('GTM Code is not defined, or correct');
      return;
    }
    // Inject the dataLayer prior to the GTM <head> script
    const datalayer = document.createElement('script');
    datalayer.innerHTML = this.dataLayerSnippet();
    document.head.appendChild(datalayer);
    // Inject the GTM <head> script
    const script = document.createElement('script');
    script.innerHTML = this.headSnippet(gtmCode);
    document.head.appendChild(script);

    // Inject the GTM <body> script
    const noscript = document.createElement('noscript');
    noscript.innerHTML = this.bodySnippet(gtmCode);
    document.body.prepend(noscript);

    this.gtmInitiated = true;
  }

  dataLayerSnippet(timestamp = Date.now(), previousURL = document.referrer) {
    return `dataLayer = [{
      'timestamp': ${timestamp},
      'previous_page': '${previousURL}'
    }];`;
  }

  headSnippet(gtmCode: string) {
    return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmCode}');`
  }

  bodySnippet(gtmCode: string) {
   return `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmCode}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
  }

  pushData(data: object) {
    if (this.gtmInitiated) {
      // @ts-ignore
      dataLayer.push(data);
    }
    return true;
  }
}
