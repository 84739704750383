import React from 'react';

export default function ProcessPayment(
  { processing, processingMessage }:
    {
      processing: boolean,
      processingMessage: string,
    }
) {
  return (
    <div className="donation-overlay" style={{display: processing ? 'block' : 'none'}} >
        <div className="donation-overlay__content">
          <p>Processing your donation.<br/>
            Please do not close the browser or navigate away from this page.</p>
          <div className="uq-loading-spinner" role="alert" aria-live="assertive">
            <span>{processingMessage}</span>
          </div>
          <p>{processingMessage}</p>
      </div>
    </div>
  );
}
