import React from 'react';
import { Metrics } from '../../classes/Metrics';

export default function Footer({metrics}: {metrics: Metrics}) {
  const linkData = {
    event: 'Custom click',
    click_category: 'Footer',
    click_action: 'Link click',
  };

  function handleLink(event: any) {
    return metrics.pushData({
      ...linkData,
      click_label: event.target.innerText,
      click_url: event.target.href,
    });
  }

  return (
    <footer className="uq-footer" data-gtm-category="Footer">
      <div className="uq-footer__container">
        <div className="uq-footer__acknowledgement">
          UQ acknowledges the Traditional Owners and their custodianship of the lands on which UQ is situated. — <a href="https://about.uq.edu.au/reconciliation" className="uq-footer__acknowledgement__link">Reconciliation statement</a>
        </div>
        <div className="uq-footer__row">
          <div className="uq-footer__column">
            <div className="uq-footer__copyright">© The University of Queensland</div>
            <div className="uq-footer__contact">
              Enquiries: <a href="tel:+61733651111" className="uq-footer__link footer__enquiries-phone" onClick={event => handleLink(event)}><span itemProp="telephone">+61 7 3365 1111</span></a>
              &nbsp; | &nbsp; <a href="https://uq.edu.au/contacts" className="uq-footer__link footer__contacts-link" onClick={event => handleLink(event)}>Contact directory</a>
            </div>
            <div className="uq-footer__meta">
              <abbr title="Australian Business Number">ABN</abbr>: 63 942 912 684 &nbsp; | &nbsp;
              <abbr title="Commonwealth Register of Institutions and Courses for Overseas Students">CRICOS</abbr> Provider No: <a className="uq-footer__link cricos__link" href="https://www.uq.edu.au/about/cricos-link" rel="external" onClick={event => handleLink(event)}>00025B</a>
            </div>
          </div>
          <div className="uq-footer__column">
            <div className="uq-footer__emergency-contact uq-footer__aside">
              <strong className="uq-footer__sub-title">Emergency</strong><br/> Phone: <a href="tel:+61733653333" className="uq-footer__link footer__emergency-phone" onClick={event => handleLink(event)}>3365 3333</a>
            </div>
          </div>
        </div>
        <div className="uq-footer__footer">
          <div className="uq-footer__row">
            <div className="uq-footer__column">
              <div className="uq-footer__footer-menu">
                <ul className="uq-footer__footer-menu__list">
                  <li className="uq-footer__footer-menu__item">
                    <a href="https://www.uq.edu.au/terms-of-use/" rel="external" className="uq-footer__footer-menu__link" onClick={event => handleLink(event)}>Privacy &amp; Terms of use</a>
                  </li>
                  <li className="uq-footer__footer-menu__item">
                    <a href="https://www.uq.edu.au/rti/" rel="external" className="uq-footer__footer-menu__link" onClick={event => handleLink(event)}>Right to Information</a>
                  </li>
                  <li className="uq-footer__footer-menu__item">
                    <a href="https://uq.edu.au/accessibility/" rel="external" className="uq-footer__footer-menu__link" onClick={event => handleLink(event)}>Accessibility</a>
                  </li>
                  <li className="uq-footer__footer-menu__item">
                    <a href="https://its.uq.edu.au/feedback?r=https://uq.edu.au" rel="external" className="uq-footer__footer-menu__link" onClick={event => handleLink(event)}>Feedback</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}