import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { login } from '../../API';
import Alert, { AlertLevel, AlertProps } from '../Alert/Alert';
import { Buffer } from 'buffer';
import LogRocket from "logrocket";

export default function Login({adminUrl}: {adminUrl: string; }): JSX.Element {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const history = useHistory();
  const location = useLocation();
  const [alertLevel, setAlertLevel] = useState<AlertLevel>(AlertLevel.INFO);
  const [alertMessage, setAlertMessage] = useState<React.ReactNode>('Loading...');
  const [alertDisplay, setAlertDisplay] = useState<boolean>(false);
  const [alertSpinner, setAlertSpinner] = useState<boolean>(true);
  const [alertSpinnerMessage, setAlertSpinnerMessage] = useState<string>('Loading...')
  const [usernameMessage, setUsernameMessage] = useState<string>('');
  const [passwordMessage, setPasswordMessage] = useState<string>('');
  const [captchaMessage, setCaptchaMessage] = useState<string>('');

  const handleLogin = useCallback(async () => {
    let error = false;
    // @ts-ignore
    const captcha = grecaptcha.enterprise.getResponse();

    if (username === '') {
      error = true;
      setUsernameMessage('Please enter a valid username');
    } else {
      setUsernameMessage('');
    }

    if (password === '') {
      error = true;
      setPasswordMessage('Please enter a password');
    } else {
      setPasswordMessage('');
    }

    if (captcha === '') {
      error = true;
      setCaptchaMessage('Please complete the challenge');
    } else {
      setCaptchaMessage('');
    }

    if (error) {
      return;
    }

    try {
      setAlertLevel(AlertLevel.INFO);
      setAlertMessage('Attempting log in...');
      setAlertSpinnerMessage('Attempting to log in...')
      setAlertSpinner(true);
      setAlertDisplay(true);
      // Firstly, lets create a local password variable
      const pw = password;
      // And revoke the one in the state for precaution
      setPassword('');
      const response = await login({
        username: username,
        password: pw,
        captcha: captcha
      });
      if (response.status === 401) {
        // @ts-ignore
        grecaptcha.enterprise.reset();
        throw new Error('Incorrect username, password, or challenge failed');
      } else if (! response.ok) {
        // @ts-ignore
        grecaptcha.enterprise.reset();
        console.error(response);
        throw new Error(response.statusText);
      }

      setAlertDisplay(false);
      const data = await response.json();
      const token = data.token;
      // Create our API access token
      const apiToken = Buffer.from(`${username}:${token}`).toString('base64');
      // Persist in localStorage to handle page refreshes (because we know this will happen)
      // Also removes dependency on state object
      localStorage.setItem('apiToken', apiToken);
      localStorage.setItem('tokenExp', data.notAfter);
      // Identify the admin user now that they are logged in
      LogRocket.identify({
        username: username,
      });
      // Redirect to dashboard
      history.push(`${adminUrl}/dashboard`);

    } catch (error: any) {
      // @ts-ignore
      grecaptcha.enterprise.reset();
      setAlertLevel(AlertLevel.ERROR);
      setAlertMessage(error.message);
      setAlertSpinnerMessage(error.message);
      setAlertSpinner(false);
      setAlertDisplay(true);
    }
  }, [username, password, adminUrl, history]);

  useEffect(() => {
    // If we hit the login page, lets remove the local token
    localStorage.removeItem('apiToken');
    localStorage.removeItem('tokenExp');

    if (location.state) {
      const alertData = location.state as AlertProps;
      setAlertLevel(alertData.level);
      setAlertMessage(alertData.message || '');
      setAlertSpinner(false);
      setAlertDisplay(true);
    }

    // Init the captcha
    setTimeout(() => {
      const elm = document.getElementById('captcha');

      if (elm?.hasChildNodes()) {
        // @ts-ignore
        grecaptcha.enterprise.reset('captcha');
      } else {
        // @ts-ignore
        grecaptcha.enterprise.render('captcha', {
          sitekey: process.env.REACT_APP_GOOGLE_API_SITE_KEY
        });
      }
    }, 1000);
  }, [location]);

  return (
    <>
      <div className="uq-layout-container admin-container">
        <h1>eDonations Administration - Login</h1>

        <Alert
          level={alertLevel}
          message={alertMessage}
          spinner={alertSpinner}
          spinnerMessage={alertSpinnerMessage}
          display={alertDisplay}
        />

        <form onSubmit={(event) => event.preventDefault()}>
          <div>
            <label htmlFor="username">Username:</label>
            <input
              className="uq-input--large"
              type="text"
              id="username"
              name="username"
              aria-describedby="usernameFeedback"
              onChange={(event) => setUsername(event.target.value)}
            />
            {usernameMessage && usernameMessage.length ?
              <span className="uq-error-message" id="usernameFeedback" aria-live="polite">
                {usernameMessage}
              </span> :
              ''
            }
          </div>

          <div>
            <label htmlFor="password">Password:</label>
            <input
              className="uq-input--large"
              type="password"
              id="password"
              name="password"
              autoComplete="off"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            {passwordMessage && passwordMessage.length ?
              <span className="uq-error-message" id="passwordFeedback" aria-live="polite">
                {passwordMessage}
              </span> :
              ''
            }
          </div>
          <div>
            <div id="captcha"></div>
            {captchaMessage && captchaMessage.length ?
              <span className="uq-error-message" id="captchaFeedback" aria-live="polite">
                {captchaMessage}
              </span> :
              ''
            }
          </div>
          <div className="admin-list-row">
            <button className="uq-button" onClick={handleLogin}>Login</button><br/>
            <Link to={'/'}>Return to public site</Link>
          </div>
        </form>
      </div>
      </>
  );
}
