import { History } from 'history';
import React, { useCallback, useEffect, useState } from 'react';
import { AlertLevel } from '../Alert/Alert';
import { handleSubmitFunction, loadData } from './config';

const id = 'titles';

export default function ConfigTitle(
  { setAlertLevel, setAlertMessage, setAlertDisplay, setAlertSpinner, adminUrl, history }:
    {
      setAlertLevel: React.Dispatch<React.SetStateAction<AlertLevel>>,
      setAlertMessage: React.Dispatch<React.SetStateAction<string>>,
      setAlertDisplay: React.Dispatch<React.SetStateAction<boolean>>,
      setAlertSpinner: React.Dispatch<React.SetStateAction<boolean>>,
      adminUrl: string,
      history: History,
    }
): JSX.Element {
  const [data, setData] = useState<Record<string, string>>({});
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [dragId, setDragId] = useState<string>();

  /**
   * Handle form submission, prevent default, save to our API, display loading, etc.
   *
   * @param event
   */
  const handleSubmit = useCallback((event) => {
    setSubmitting(true);
    handleSubmitFunction({
      event,
      setAlertLevel,
      setAlertMessage,
      setAlertDisplay,
      setAlertSpinner,
      history,
      adminUrl,
      id,
      data,
    }).then(() => setSubmitting(false));
  }, [history, data, adminUrl, setAlertDisplay, setAlertSpinner, setAlertMessage, setAlertLevel]);

  /**
   * load the initial data via callback to prevent loops on data change
   */
  const loadInitialData = useCallback(() => {
    setSubmitting(true);
    loadData({
      setAlertLevel,
      setAlertMessage,
      setAlertDisplay,
      setAlertSpinner,
      history,
      adminUrl,
      id,
      setData,
      data,
    }).then(() => {
      setSubmitting(false);
    });
  }, [history, adminUrl, setAlertDisplay, setAlertSpinner, setAlertMessage, setAlertLevel]);

  /**
   * Will loop over the objects in the given data set and reindex them.
   * Used when moving or removing items to ensure correct ordering and index is maintained.
   *
   * @param data
   *
   * @return void
   */
  function reindexItems(data: any): void {
    // Return an array of the items ordered by key as [[key, value], [key, value]]
    const ordered = Object.entries(data).sort(([keyA, valueA], [keyB, valueB]) => {
      return Number(keyA) - Number(keyB);
    });

    // Pluck out the value only, as that is all we are using
    const obj = ordered.map(([/* key */, value]) => {
      return value;
    });
    // @ts-ignore
    setData({ ...obj });
  }

  /**
   * Handle drop even for drag-and-drop item
   *
   * @param event
   *
   * @return void
   */
  const handleDrop = (event: any): void => {
    const dataObj = {...data};
    let dropItem;

    const position = event.currentTarget.getBoundingClientRect();
    const elMid = event.currentTarget.offsetHeight / 2;
    if ((event.clientY - position.top) < elMid) {
      dropItem = Number(event.currentTarget.id) - 0.5;
    } else {
      dropItem = Number(event.currentTarget.id) + 0.5;
    }

    // Delete the original object
    delete dataObj[String(dragId)];
    // @ts-ignore - Set the new object with the half value used to reposition without overwriting
    dataObj[dropItem] = document.getElementById(dragId).querySelector(':scope > input').value;
    reindexItems(dataObj);
  };


  /**
   * Handle a value change on the form
   * This prevents us needing to set a state for each individual item.
   *
   * @param event
   */
  const handleChange = useCallback((event) => {
    setData(function(data) {
      return {
        ...data,
        [event.target.name]: event.target.value
      };
    });
  }, []);

  /**
   * Append a new record to the current dataset
   *
   * @return void
   */
  function addNew(): void {
    setData({...data, [Object.keys(data).length + 1]: ''});
  }

  /**
   * Remove a row from the current dataset and then recalculate order
   *
   * @param key
   *
   * @return void
   */
  function removeRow(key: string) {
    const dataObj = {...data};
    delete dataObj[key];
    reindexItems(dataObj);
  }

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  return (
    <>
      <div className="uq-layout-container admin-container">
        <h1>eDonations Administration - Titles</h1>

        <form className="margin-b1" onSubmit={event => handleSubmit(event)}>
          <div className="grid-buttons margin-b1">
            <button type="submit" className="uq-button" disabled={submitting}>Save</button>
            <p>Drag and drop to rearrange the items.</p>
          </div>

          <div className="uq-grid">

            { Object.entries(data).map(([key, value]) => {
              return <div
                className="uq-grid__col uq-grid__col--6 uq-grid__col--md-5 uq-grid__col--xl-8 admin-list-row"
                key={`${key}`}
                draggable={true}
                onDragOver={(event) => event.preventDefault()}
                onDragStart={(event) => setDragId(event.currentTarget.id)}
                onDrop={handleDrop}
                id={key}
              >
                <span className="order-handle">&hellip;</span>
                <input
                  type="text"
                  name={key}
                  className="admin-input"
                  value={value}
                  onChange={event => handleChange(event)}
                />
                <button className="uq-button uq-button--alert" onClick={() => removeRow(key)} type="button">Remove</button>
              </div>
            })}
          </div>
        </form>

        <div className="uq-grid__col">
          <button className="uq-button" onClick={addNew} type="button">Add New Row</button>
        </div>
      </div>
    </>
  );
}
