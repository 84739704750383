import React, { isValidElement } from 'react';

export enum AlertLevel {
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
}

export interface AlertProps {
  /** Valid levels are "info", "success", "warning", "error" */
  level: AlertLevel;
  /** The message to be displayed */
  message: React.ReactNode;
  /** Should the loading spinner be displayed */
  spinner?: boolean;
  /** Spinner message for accessibility */
  spinnerMessage?: string;
  /** Is displayed */
  display: boolean;
  /** Use a container class */
  container?: boolean;
}

/**
 * Display an alert message as per https://design-system.uq.edu.au/?path=/docs/components-alert--alert-info#page-alerts
 *
 * @param props
 * @constructor
 */
export default function Alert(props: AlertProps) {
  return (
    <>
      <div className={`uq-alert uq-alert--${props.level || AlertLevel.INFO} ${props.container ? 'uq-layout-container' : ''}`} style={{display: props.display ? '' : 'none'}} role="alert">
        <div className="uq-alert__message">
          {isValidElement(props.message)
            ? props.message
            : <p>{props.message}</p>
          }
        </div>
        {props.spinner &&
        <div className="uq-loading-spinner payment-loading__spinner" role="alert" aria-live="assertive">
          <span>{props.spinnerMessage || ''}</span>
        </div>
        }
      </div>
    </>
  );
}