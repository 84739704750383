import { History } from 'history';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { logout } from '../../API';

export default function AdminHeader({ adminUrl, history }: { adminUrl: string, history: History }): JSX.Element {
  const handleLogout = useCallback(async (event) => {
    event.preventDefault();
    try {
      await logout();
      localStorage.removeItem('apiToken');
      localStorage.removeItem('tokenExp');
      history.push(adminUrl);
    } catch (error) {
      console.error(error);
    }
  }, [adminUrl, history]);

  return (
    <div className="uq-site-header">
      {/* Site title and utility area with mobile nav toggler (JS) */}
      <div className="uq-site-header__title-container">
        <div className="uq-site-header__title-container__left">
          <Link to={`${adminUrl}/dashboard`} className="uq-site-header__title">Admin Dashboard</Link>
        </div>
        <div className="uq-site-header__title-container__right">
          <div>
            <Link to={adminUrl} onClick={(event) => handleLogout(event)}>Log out</Link>
          </div>
          <button className="uq-site-header__navigation-toggle jsNavToggle">Menu</button>
        </div>
      </div>

      {/* Navigation Menu */}
      <div className="uq-site-header__navigation-container">
        <nav className="uq-site-header__navigation" aria-label="Site navigation" id="jsNav">
          <ul className="uq-site-header__navigation__list uq-site-header__navigation__list--level-1">
            <li className="uq-site-header__navigation__list-item">
              <Link to={`${adminUrl}/donations`}>Donations</Link>
            </li>
            <li className="uq-site-header__navigation__list-item">
              <Link to={`${adminUrl}/config/alias`}>Configure Aliases</Link>
            </li>
            <li className="uq-site-header__navigation__list-item">
              <Link to={`${adminUrl}/config/title`}>Configure Titles</Link>
            </li>
            <li className="uq-site-header__navigation__list-item">
              <Link to={`${adminUrl}/administrators`}>Manage Administrators</Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
