import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import { Metrics } from './classes/Metrics';
import Admin from './components/Admin/Admin';
import Login from './components/Admin/Login';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Main from './components/Main/Main';
import SiteHeader from './components/SiteHeader/SiteHeader';
import LogRocket from 'logrocket';

const metrics = new Metrics();
const adminUrl = '/admin';

function App() {
  useEffect(() => {
    metrics.initGTM('GTM-NC7M38Q');
    LogRocket.init('0fdi45/edonations', {
      release: process.env.REACT_APP_VERSION,
    });
  });

  return (
    <div className="App">
      <Header metrics={metrics} />
      <Router>
        <Switch>
          <Route exact path={adminUrl}>
            <Login adminUrl={adminUrl}/>
          </Route>
          <Route path={adminUrl}>
            <Admin adminUrl={adminUrl} />
          </Route>
          <Route path="*">
            <SiteHeader metrics={metrics} />
            <div id="content">
              <Main metrics={metrics} />
            </div>
          </Route>
        </Switch>
      </Router>
      <Footer metrics={metrics} />
    </div>
  );
}

export default App;
