import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Donation, paramsToLower } from '../../classes/Donation';
import { Fund } from '../../classes/Fund';
import { Metrics } from '../../classes/Metrics';
import { inputSuccess } from '../../Helpers';
import Alert, { AlertLevel } from "../Alert/Alert";

export default function SelectAmount(
  { donation, donationAmount, frequency, fund, setFundUrl, setDonationAmount, setFrequency, setDonation, setAppealId, metrics }:
  {
    donation: Donation,
    donationAmount: number,
    frequency: string,
    fund: Fund,
    setFundUrl: React.Dispatch<React.SetStateAction<string>>,
    setDonationAmount: React.Dispatch<React.SetStateAction<number>>,
    setFrequency: React.Dispatch<React.SetStateAction<string>>,
    setDonation: React.Dispatch<React.SetStateAction<Donation>>,
    setAppealId: React.Dispatch<React.SetStateAction<string>>,
    metrics: Metrics
  }
) {
  const [currentDonation, setCurrentDonation] = useState<Donation>(donation ?? null);
  const [amountError, setAmountError] = useState<boolean>(false);
  const [recurring, setRecurring] = useState<boolean>(false);
  const [frequencyError, setFrequencyError] = useState<boolean>(false);
  const [inspiration, setInspiration] = useState<string>('');
  const history = useHistory();
  // @ts-ignore
  let { fundUrl } = useParams();
  const linkData = {
    event: 'Custom click',
    click_category: 'UQ giving donation app',
    click_action: 'Enter an amount or select a preset amount',
  };

  /**
   * Validate form and provide error messages if data is not correct.
   */
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const errorAmount = (donationAmount < 5 || donationAmount > 50000);
    const errorFrequency = (recurring && !Object.values(Donation.PERMITTED_FREQUENCY).includes(frequency));

    // Check donation amount
    setAmountError(errorAmount);
    setFrequencyError(errorFrequency);
    // Not using the state here directly to remove the click lag
    if (!errorAmount && !errorFrequency) {
      const existing = localStorage.getItem('donation');
      let newDonation = new Donation();

      // If the user has completed a successful donation, use the existing data for this from local storage
      // Else if the user is in the middle of completing a donation (currentDonation), use this donation from the state
      if (existing) {
        const previous: Donation = JSON.parse(existing);
        // Reset some data that we do not want to import into the new donation object
        delete previous.identifier;
        delete previous.donationStatus;
        delete previous.chartString;
        previous.responseAt = '';
        previous.invoiceNumber = '';
        previous.receiptNumber = '';
        previous.fundName = '';
        previous.transactionCode = '';
        previous.paymentConfirmed = false;
        // Initialise the new donation with this information first
        newDonation = {...previous};
      } else if (currentDonation) {
        newDonation = {...currentDonation};
      }

      newDonation.amount = donationAmount;
      newDonation.fundUrl = fundUrl;
      newDonation.recurring = frequency !== Donation.ONCE;
      newDonation.recurringPeriod = frequency;
      newDonation.inspiration = inspiration;
      setDonation(newDonation);
      metrics.pushData({
        ...linkData,
        click_action: 'Step click',
        click_label: 'Next',
        click_url: `${window.location.origin}/${fundUrl}/${donationAmount}`
      });
      history.push(`/${fundUrl}/${donationAmount}`);
    }
  }

  useEffect(() => {
    document.getElementById('content')?.scrollIntoView({
      behavior: 'smooth'
    });
    setFundUrl(fundUrl);
    const lowerCaseParams = paramsToLower(history);
    if (lowerCaseParams.has('appeal_id')) {
      setAppealId(lowerCaseParams.get('appeal_id') || '');
    }
  }, [history, fundUrl, setAppealId, setFundUrl]);

  return (
    <div className="uq-grid uq-grid--golden">
      <div className="uq-grid__col">

        <span className="fund-steps">Step 1 of 4</span>
        <h1 className="gift-heading">My gift to {fund.friendlyName}</h1>
        <h2 className="step-heading">How often and how much would you like to donate?</h2>
        <form className="donation-form" onSubmit={event => handleSubmit(event)}>
          <fieldset>
            <legend>How often would you like to make this donation?</legend>
            {/* Explicit control-label association */}
            <input
              id="once"
              type="radio"
              name="often"
              aria-describedby="oftenFeedback"
              onChange={() => {
                setFrequency(Donation.ONCE);
                setRecurring(false);
                metrics.pushData({
                  ...linkData,
                  click_action: 'Select donation period',
                  click_label: Donation.ONCE,
                  click_url: undefined
                });
              }}
              checked={frequency === Donation.ONCE}
            />
            <label htmlFor="once">One off donation</label><br/>
            <input
              id="recurring"
              type="radio"
              name="often"
              aria-describedby="oftenFeedback"
              onChange={() => {
                setRecurring(true);
                setFrequency('select');
                metrics.pushData({
                  ...linkData,
                  click_action: 'Select donation period',
                  click_label: Donation.RECURRING,
                  click_url: undefined
                });
              }}
              checked={frequency !== Donation.ONCE}
            />
            <label htmlFor="recurring">Recurring donation</label><br/>

            <select
              className={frequency === Donation.ONCE ? 'hide' : ''}
              onChange={(event) => setFrequency(event.target.value)}
              defaultValue=''
            >
              <option value='' disabled={true}>Select a duration</option>
              {Object.values(Donation.RECURRING_PERIODS).map((value) => {
                return <option value={value} key={value}>
                  {/* A rough ucFirst implementation */}
                  {value.substring(0, 1).toUpperCase()}{value.substring(1)}
                </option>
              })}
            </select>
            {frequencyError &&
              <span
                id="oftenFeedback"
                aria-live="polite"
                className={frequency === Donation.ONCE ? 'hide' : 'uq-error-message'}
              >{frequencyError && 'Please select how often you would like to make this donation.'}</span>
            }
          </fieldset>

          <label htmlFor="amount">Enter an amount or select a preset amount</label>
          <input
            type="number"
            id="amount"
            className="donation-amount"
            name="amount"
            aria-describedby="amountFeedback"
            value={donationAmount ? donationAmount : ''}
            onChange={event => setDonationAmount(Number(event.target.value.replace(/[^0-9]*/g,'')))}
          />
          {amountError &&
            <span id="amountFeedback" aria-live="polite" className="uq-error-message">The donation amount must be between $5 and $50000.</span>
          }

          <div className="grid-amounts">
            <button type="button" className="uq-pill uq-pill--purple uq-pill--outline"
              onClick={() => {
                setDonationAmount(50);
                metrics.pushData({
                  ...linkData,
                  click_label: '$50',
                  click_url: undefined
                });
              }}>$50</button>
            <button type="button" className="uq-pill uq-pill--purple uq-pill--outline"
              onClick={() => {
                setDonationAmount(100);
                metrics.pushData({
                  ...linkData,
                  click_label: '$100',
                  click_url: undefined
                });
              }}>$100</button>
            <button type="button" className="uq-pill uq-pill--purple uq-pill--outline"
              onClick={() => {
                setDonationAmount(500);
                metrics.pushData({
                  ...linkData,
                  click_label: '$500',
                  click_url: undefined
                });
              }}>$500</button>
            <button type="button" className="uq-pill uq-pill--purple uq-pill--outline"
              onClick={() => {
                setDonationAmount(1000);
                metrics.pushData({
                  ...linkData,
                  click_label: '$1000',
                  click_url: undefined
                });
              }}>$1000</button>
            <button type="button" className="uq-pill uq-pill--purple uq-pill--outline"
              onClick={() => {
                setDonationAmount(2000);
                metrics.pushData({
                  ...linkData,
                  click_label: '$2000',
                  click_url: undefined
                });
              }}>$2000</button>
          </div>

          <div>
            <label htmlFor="inspiration">What inspired you to give?</label>
            <input
              className="uq-input--large"
              type="text"
              id="inspiration"
              name="inspiration"
              value={inspiration}
              onChange={event => setInspiration(event.target.value)}
              onBlur={(event) => {
                inputSuccess(event);
              }}
            />
          </div>

          <div className="grid-buttons">
            <button type="submit" className="uq-button">Next</button>
            <Link to={`/`} className="uq-button uq-button--text" onClick={() => {
              metrics.pushData({
                ...linkData,
                click_action: 'Step click',
                click_label: 'Back',
                click_url: window.location.origin
              });
            }}>Back</Link>
          </div>

          <Alert
            container={true}
            level={AlertLevel.INFO}
            message={
              <>
                <p>If you experience any issues processing your donation, please call UQ Advancement and Community
                  Engagement team on<a href="tel:+61733463909" style={{marginLeft: '5px'}}>07 3346 3909</a> and one
                  of our team members will be happy to assist.</p>
                <p>Thank you for your support.</p>
              </>
            }
            display={true}
          />
        </form>

      </div>
    </div>
  );
}
