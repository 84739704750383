import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import * as API from '../../API';
import { Donation } from '../../classes/Donation';
import { Fund } from '../../classes/Fund';
import Address from '../Address/Address';
import ConfirmDonation from '../ConfirmDonation/ConfirmDonation';
import FeaturedFunds from '../FeaturedFunds/FeaturedFunds';
import LocationAware from '../LocationAware/LocationAware';
import PersonalDetails from '../PersonalDetails/PersonalDetails';
import SelectAmount from '../SelectAmount/SelectAmount';
import SelectFund from '../SelectFund/SelectFund';
import Success from '../Success/Success';

export default function Main({metrics}: any) {
  const location = useLocation();
  const [fund, setFund] = useState<Fund>(new Fund());
  const [fundUrl, setFundUrl] = useState<string>(location.pathname.split('/')[1]);
  const [fundError, setFundError] = useState<boolean>(false);
  const [donationAmount, setDonationAmount] = useState<number>(0);
  const [frequency, setFrequency] = useState<string>(Donation.ONCE);
  const [donation, setDonation] = useState<Donation>(new Donation());
  const [appealId, setAppealId] = useState<string>('');
  const history = useHistory();

  async function getFund(fundUrl: string, history: History<unknown>) {
    if (fundUrl.length) {
      const response = await API.getFundByUrl(fundUrl);
      if (Object.keys(response).length === 0) {
        setFundError(true); // Set error state
        setFundUrl(''); // Remove the set fund
        history.push(''); // Reset the URL
      } else {
        setFund(response);
      }
      return response;
    }
  }

  useEffect(() => {
    if (! ['confirm', 'success', Fund.OTHER].includes(fundUrl)) {
      getFund(fundUrl, history).then(/* Ignore promise result */);
    }
  }, [fundUrl, history]);

  return (
    <Router>
      <Switch>
        {/* Summary page before direction to UQ Payments */}
        <Route exact path="/confirm">
          <ConfirmDonation
            fund={fund}
            donation={donation}
            setDonation={setDonation}
            appealId={appealId}
            metrics={metrics}
          />
        </Route>

        {/* Success once payment has been confirmed successfully */}
        <Route exact path="/success">
          <Success donation={donation} metrics={metrics}/>
        </Route>

        {/* Route to address page */}
        {!fundError &&
          <Route path="/:fundUrl/:donationAmount/address">
            <Address
              donation={donation}
              fund={fund}
              setDonation={setDonation}
              metrics={metrics}
              setAppealId={setAppealId}
            />
          </Route>
        }

        {/* Route to details page */}
        {!fundError &&
        <Route path="/:fundUrl/:donationAmount">
          <PersonalDetails
            fund={fund}
            donation={donation}
            setDonation={setDonation}
            metrics={metrics}
          />
        </Route>
        }

        {/* Route to donation amount if no fund error */}
        {!fundError &&
        <Route path="/:fundUrl">
          <SelectAmount
            donation={donation}
            setFundUrl={setFundUrl}
            fund={fund}
            setDonationAmount={setDonationAmount}
            donationAmount={donationAmount}
            frequency={frequency}
            setFrequency={setFrequency}
            setDonation={setDonation}
            setAppealId={setAppealId}
            metrics={metrics}
          />
        </Route>
        }

        {/* Default route Home page for fund selection */}
        <Route>
          <SelectFund
            error={fundError}
            setError={setFundError}
            setFund={setFund}
            metrics={metrics}
            setAppealId={setAppealId}
          />
          <LocationAware />
          <FeaturedFunds setError={setFundError} metrics={metrics} />
        </Route>

      </Switch>
    </Router>
  );
}
