import React, { useState } from 'react';
import { Metrics } from '../../classes/Metrics';

export default function Header({metrics}: {metrics: Metrics}) {
  const [site, setSite] = useState<string>('one');
  const linkData = {
    event: 'Custom click',
    click_category: 'Header',
    click_action: 'Link click',
  };

  function handleLink(event: any) {
    return metrics.pushData({
      ...linkData,
      click_label: event.target.innerText,
      click_url: event.target.href,
    });
  }

  function handleImgLink(event: any) {
    return metrics.pushData({
      ...linkData,
      click_label: event.target.alt,
      click_url : event.target.parentElement.href,
    });
  }

  return (
    <header className="uq-header">
      <div className="uq-header__container">
        <div className="uq-header__logo">
          <a className="uq-header__logo--large" href="https://www.uq.edu.au/"
             onClick={event => handleImgLink(event)}
          >
            <img alt="The University of Queensland" src="https://static.uq.net.au/v11/logos/corporate/uq-lockup-landscape--reversed.svg"/>
          </a>
          <a className="uq-header__logo--small" href="https://www.uq.edu.au/"
             onClick={event => handleImgLink(event)}
          >
            <img alt="The University of Queensland" src="https://static.uq.net.au/v11/logos/corporate/uq-logo--reversed.svg"/>
          </a>
        </div>
        <div className="uq-header__search-toggle">
          <button className="uq-header__search-toggle-button" type="button">Search</button>
        </div>
        <div className="uq-header__search">
          <form action="https://www.uq.edu.au/search/" method="get" acceptCharset="UTF-8">
            <fieldset>
              <legend className="uq-header__search-title">What are you looking for?</legend>
              <div className="uq-header__search-range">
                <input
                  type="radio"
                  id="edit-as_sitesearch-off"
                  name="as_sitesearch"
                  defaultValue=""
                  className="form-radio"
                  checked={site === 'all'}
                  onChange={() => setSite('all')}
                />
                <label htmlFor="edit-as_sitesearch-off" className="option uq-header__search-label">
                  Search all UQ websites
                </label>
              </div>
              {/* Customise the site search URL for your website */}
              <div className="uq-header__search-range">
                <input
                  type="radio"
                  id="edit-as_sitesearch-on"
                  name="as_sitesearch"
                  value="https://alumni.uq.edu.au/giving/"
                  className="form-radio uq-header__search-radio"
                  checked={site === 'one'}
                  onChange={() => setSite('one')}
                />
                <label htmlFor="edit-as_sitesearch-on" className="option uq-header__search-label">
                  Search this website (alumni.uq.edu.au/giving/)
                </label>
              </div>
              <div className="uq-header__search-query">
                <label htmlFor="edit-q" className="visually-hidden">Search term</label>
                <input type="text" id="edit-q" name="q" size={60} maxLength={128} className="uq-header__search-query-input"/>
                <span className="uq-header__search-query-button">
                  <input type="submit" name="op" value="Search" className="uq-header__search-query-submit"/>
                </span>
              </div>
            </fieldset>
          </form>
        </div>
        <nav className="uq-header__navigation">
          <ul className="uq-header__navigation-container">
            <li>
              <a className="uq-header__navigation-link" href="https://contacts.uq.edu.au/" onClick={event => handleLink(event)}>Contacts</a>
            </li>
            <li><a className="uq-header__navigation-link" href="https://future-students.uq.edu.au/" onClick={event => handleLink(event)}>Study</a></li>
            <li><a className="uq-header__navigation-link" href="https://maps.uq.edu.au/" onClick={event => handleLink(event)}>Maps</a></li>
            <li><a className="uq-header__navigation-link" href="https://www.uq.edu.au/news/" onClick={event => handleLink(event)}>News</a></li>
            <li><a className="uq-header__navigation-link" href="https://www.uq.edu.au/events/" onClick={event => handleLink(event)}>Events</a></li>
            <li><a className="uq-header__navigation-link" href="https://jobs.uq.edu.au/" onClick={event => handleLink(event)}>Jobs</a></li>
            <li><a className="uq-header__navigation-link" href="https://www.library.uq.edu.au/" onClick={event => handleLink(event)}>Library</a></li>
            <li><a className="uq-header__navigation-link" href="https://giving.uq.edu.au/" onClick={event => handleLink(event)}>Give now</a></li>
            <li><a className="uq-header__navigation-link" href="https://my.uq.edu.au/" onClick={event => handleLink(event)}>my.UQ</a></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}
