import { Donation } from './classes/Donation';
import { Fund } from './classes/Fund';
import { Location, LookupResponse } from './interfaces';

const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/v1`;

/**
 * Response interfaces
 */
interface SearchResponse {
  total: number;
  hits: Array<{[key: string]: any}>;
  max_score?: number;
}

// TODO - Handle API errors

/**
 * Locate a user
 *
 * @param coords
 *
 * @return Promise<string>
 */
export async function locate(coords: Location): Promise<string> {
  const response = await fetch(`${endpoint}/locate`, {
    method: 'POST',
    body: JSON.stringify(coords),
  });
  return await response.text();
}

/**
 * Retrieve a list of featured funds
 *
 * @return Promise<Array<Fund>>
 */
export async function getFeaturedFunds(): Promise<Array<Fund>> {
  const response = await fetch(`${endpoint}/featuredFunds`, {
    method: 'GET',
  });
  return await response.json();
}

/**
 * Lookup a fund by ID
 *
 * @param id
 *
 * @return Promise<Fund>
 */
export async function getFund(id: string): Promise<Fund> {
  const response = await fetch(`${endpoint}/fund/${id}`, {
    method: 'GET',
  });
  return await response.json();
}

/**
 * Lookup a fund by ID
 *
 * @param url
 *
 * @return Promise<Fund>
 */
export async function getFundByUrl(url: string): Promise<Fund> {
  const response = await fetch(`${endpoint}/fund-url/${url}`, {
    method: 'GET',
  });
  return await response.json();
}

/**
 * Search for funds based on a given search term
 *
 * @param searchTerm
 *
 * @return Promise<SearchResponse>
 */
export async function search(searchTerm: string): Promise<SearchResponse> {
  const response = await fetch(`${endpoint}/fund`, {
    method: 'POST',
    body: JSON.stringify({ term: searchTerm }),
  });
  return await response.json();
}

/**
 * Add a new Donation
 *
 * @param input
 *
 * @return Promise<Response>
 */
export async function addDonation(input: Donation): Promise<Response> {
  return await fetch(`${endpoint}/donation`, {
    method: 'POST',
    body: JSON.stringify(input),
  });
}

/*
  EXPERIAN FUNCTIONS
 */

/**
 * Search for addresses in Experian
 *
 * @param address
 * @param country
 *
 * @return Promise<Response>
 */
export async function addressSearch(address: string, country: string): Promise<Response> {
  const response = await fetch(`${endpoint}/address/search/${address}/${country}`, {
    method: 'GET',
  });
  return response;
}

/**
 * Search for addresses in Experian
 *
 * @param globalAddressKey
 *
 * @return Promise<Response>
 */
export async function addressValidation(globalAddressKey: string): Promise<Response> {
  const response = await fetch(`${endpoint}/address/validate?address=${globalAddressKey}`, {
    method: 'GET',
  });
  return response;
}

/**
 * Search for email addresses in Experian
 *
 * @param emailAddress
 *
 * @return Promise<Response>
 */
export async function emailValidation(emailAddress: string): Promise<Response> {
  const response = await fetch(`${endpoint}/email/validate/${emailAddress}`, {
    method: 'GET',
  });
  return response;
}


/**
 * Search for email addresses in Experian
 *
 * @param phone
 *
 * @return Promise<Response>
 */
export async function phoneValidation(phone: string): Promise<Response> {
  const response = await fetch(`${endpoint}/phone/validate/${phone}`, {
    method: 'GET',
  });
  return response;
}

/*
  ADMIN FUNCTIONS
 */

/**
 * Login a user via credentials
 *
 * @param input
 *
 * @return Promise<Response>
 */
export async function login(input: { [key: string]: string }): Promise<Response> {
  const response = await fetch(`${endpoint}/admin/login`, {
    method: 'POST',
    body: JSON.stringify(input)
  });
  return response;
}

/**
 * Logout a user
 *
 * @return Promise<Response>
 */
export async function logout(): Promise<Response> {
  const token = localStorage.getItem('apiToken');
  const response = await fetch(`${endpoint}/admin/logout`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response;
}

/**
 * List Admins
 *
 * @return Promise<Response>
 */
export async function listAdmins(): Promise<Response> {
  const token = localStorage.getItem('apiToken');
  const response = await fetch(`${endpoint}/admin/users`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response;
}

/**
 * Create / Update a user
 *
 * @param input
 *
 * @return Promise<Response>
 */
export async function upsertAdmin(input: { [key: string]: string|undefined }): Promise<Response> {
  const token = localStorage.getItem('apiToken');
  const response = await fetch(`${endpoint}/admin/user`, {
    method: 'POST',
    body: JSON.stringify(input),
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response;
}

/**
 * Delete a user
 *
 * @param username
 *
 * @return Promise<Response>
 */
export async function removeAdmin(username: string): Promise<Response> {
  const token = localStorage.getItem('apiToken');
  const response = await fetch(`${endpoint}/admin/user`, {
    method: 'DELETE',
    body: JSON.stringify({ username }),
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response;
}

/**
 * Get Admin Configuration
 *
 * @param config
 *
 * @return Promise<Response>
 */
export async function getConfig(config: string): Promise<Response> {
  const response = await fetch(`${endpoint}/admin/config/${config}`, {
    method: 'GET',
  });

  return response;
}

/**
 * Set Admin Configuration
 *
 * @param config
 * @param input
 *
 * @return Promise<Response>
 */
export async function setConfig(config: string, input: object): Promise<Response> {
  const token = localStorage.getItem('apiToken');
  const response = await fetch(`${endpoint}/admin/config/${config}`, {
    method: 'PUT',
    body: JSON.stringify(input),
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response;
}

/**
 * Get a list of donations based on applied filters
 *
 * @param input
 *
 * @return Promise<Response>
 */
export async function getDonations(input: any): Promise<Response> {
  const token = localStorage.getItem('apiToken');
  const response = await fetch(`${endpoint}/report/donation`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(input)
  });

  return response;
}

/**
 * Batch update Donation Processed state
 *
 * @param input
 *
 * @return Promise<Response>
 */
export async function saveProcessState(input: object): Promise<Response> {
  const token = localStorage.getItem('apiToken');
  const response = await fetch(`${endpoint}/admin/donation`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(input)
  });

  return response;
}

/**
 * Verify a donation with the payment gateway
 *
 * @param project         The Project identifier so the Payment System knows who is making the request
 * @param code            The transaction code to lookup
 * @param paymentGateway  The payment gateway we are looking up the transaction in
 * @param environment     The environment of the request ('prod', 'dev', etc.)
 *
 * @return Promise<LookupResponse>
 */
export async function lookupTransaction(project: string, code: string, paymentGateway: string, environment: string): Promise<LookupResponse> {
  const response = await fetch(`${process.env.REACT_APP_PAYMENT_INTERFACE_SYSTEM_URL}/v1/lookup`, {
    method: 'POST',
    body: JSON.stringify({project, code, paymentGateway, environment}),
  });
  return await response.json();
}
