import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as API from '../../API';
import { Fund } from '../../classes/Fund';
import { Metrics } from '../../classes/Metrics';

export default function FeaturedFunds(
  { setError, metrics }:
  {
    setError: React.Dispatch<React.SetStateAction<boolean>>,
    metrics: Metrics,
  }
) {
  const [featuredFunds, setFeaturedFunds] = useState<Array<Fund>>([]);
  const linkData = {
    event: 'Custom click',
    click_category: 'UQ giving donation app',
    click_action: 'Select a fund',
  };

  const loadData = useCallback(async () => {
    try {
      const response = await API.getFeaturedFunds();
      setFeaturedFunds(response);
    } catch (error) {
      setError(true);
      console.error(error);
    }
  }, [setError]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="uq-section uq-section--centered uq-section--shaded">
      <div className="uq-section__container">
        <div className="uq-section__content">
          <h2>Featured funds</h2>
        </div>
      </div>

      <div className="uq-grid uq-grid--thirds">
        {/* Loop over our featured funds */}
        {featuredFunds.length !== 0 && featuredFunds.map((fund: Fund) => {
          return <div key={fund.id} className="uq-grid__col">
            <div className="uq-card">
              <div className="uq-card__content-wrapper">
                <div className="uq-card__content">
                  <div className="uq-card__header">
                    <h3 className="uq-card__title">{fund.friendlyName}</h3>
                  </div>
                  <div className="uq-card__body">
                    <p>{fund.overview}</p>
                  </div>
                </div>
                <div className="uq-card__actions">
                  <Link
                    className="uq-button uq-button--purple"
                    onClick={() => {
                      setError(false);
                      return metrics.pushData({
                        ...linkData,
                        click_label: fund.friendlyName,
                        click_url: `${window.location.href}${fund.url || fund.id}`
                      });
                    }}
                    to={{
                      pathname: `/${fund.url || fund.id}`
                    }}
                  >Donate</Link>
                </div>
              </div>
            </div>
          </div>;
        })}
      </div>
    </div>
  );
}
