import React from 'react';

interface SpinnerProps {
  /** A message for accessibility and possibly user display */
  spinnerMessage?: string;
  /** Should the message be displayed below the spinner */
  messageVisible?: boolean;
  /** Is displayed */
  display: boolean;
}

/**
 * Display a spinner as per https://design-system.uq.edu.au/?path=/docs/components-loading-spinner--loading-spinner
 *
 * @param props
 * @constructor
 */
export default function LoadingSpinner(props: SpinnerProps) {
  return (
    <div className="loading-spinner-container" style={{display: props.display ? '' : 'none'}} >
      <div className="uq-loading-spinner" role="alert" aria-live="assertive">
        <span>{props.spinnerMessage}</span>
      </div>
      <p>{ props.messageVisible ? props.spinnerMessage : '' }</p>
    </div>
  );
}