import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import Alert, { AlertLevel } from '../Alert/Alert';
import AdminHeader from './AdminHeader';
import Administrators from './Administrators';
import ConfigAlias from './ConfigAlias';
import ConfigTitle from './ConfigTitle';
import Dashboard from './Dashboard';
import Donations from './Donations';

export default function Admin({ adminUrl }: { adminUrl: string }): JSX.Element {
  const history = useHistory();
  const [alertLevel, setAlertLevel] = useState<AlertLevel>(AlertLevel.INFO);
  const [alertMessage, setAlertMessage] = useState<string>('Loading...');
  const [alertDisplay, setAlertDisplay] = useState<boolean>(false);
  const [alertSpinner, setAlertSpinner] = useState<boolean>(true);

  useEffect(() => {
    if (! localStorage.getItem('apiToken')) {
      history.push(adminUrl, { message: 'Session expired. Please sign in to continue', level: AlertLevel.WARNING });
    }
  });

  // TODO - Refactor alert to use an object instead of large repetition of props

  return (
    <>
      <Router>
        <AdminHeader adminUrl={adminUrl} history={history} />
        <Alert
          container={true}
          level={alertLevel}
          message={alertMessage}
          spinner={alertSpinner}
          spinnerMessage={alertMessage}
          display={alertDisplay}
        />
        <Switch>
          <Route exact path={`${adminUrl}/dashboard`}>
            <Dashboard adminUrl={adminUrl} />
          </Route>
          <Route path={`${adminUrl}/config/alias`}>
            <ConfigAlias
              setAlertLevel={setAlertLevel}
              setAlertMessage={setAlertMessage}
              setAlertDisplay={setAlertDisplay}
              setAlertSpinner={setAlertSpinner}
              adminUrl={adminUrl}
              history={history}
            />
          </Route>
          <Route path={`${adminUrl}/config/title`}>
            <ConfigTitle
              setAlertLevel={setAlertLevel}
              setAlertMessage={setAlertMessage}
              setAlertDisplay={setAlertDisplay}
              setAlertSpinner={setAlertSpinner}
              adminUrl={adminUrl}
              history={history}
            />
          </Route>
          <Route path={`${adminUrl}/donations`}>
            <Donations
              setAlertLevel={setAlertLevel}
              setAlertMessage={setAlertMessage}
              setAlertDisplay={setAlertDisplay}
              setAlertSpinner={setAlertSpinner}
              adminUrl={adminUrl}
              history={history}
            />
          </Route>
          <Route path={`${adminUrl}/administrators`}>
            <Administrators
              setAlertLevel={setAlertLevel}
              setAlertMessage={setAlertMessage}
              setAlertDisplay={setAlertDisplay}
              setAlertSpinner={setAlertSpinner}
              adminUrl={adminUrl}
              history={history}
            />
          </Route>
        </Switch>
      </Router>
      <div className="uq-layout-container" style={{display: 'flex', justifyContent: 'flex-end'}}>
        <small>Version: {process.env.REACT_APP_VERSION}</small>
      </div>
    </>
  );
}
