export class Fund {
  id: string;
  shortName: string;
  longName: string;
  friendlyName: string;
  overview: string;
  position: number;
  chartString: string;
  receiptTemplate: string;
  acknowledgementTemplate: string;
  url: string;

  constructor(
    id = '',
    shortName = '',
    longName = '',
    friendlyName = '',
    overview = '',
    position = 0,
    chartString = '',
    receiptTemplate = '',
    acknowledgementTemplate = '',
    url = '',
  ) {
    this.id = id;
    this.longName = longName;
    this.shortName = shortName;
    this.friendlyName = friendlyName;
    this.overview = overview;
    this.position = position;
    this.chartString = chartString;
    this.receiptTemplate = receiptTemplate;
    this.acknowledgementTemplate = acknowledgementTemplate;
    this.url = url;
  }

  static readonly OTHER = 'Other';
}
